import React, { useContext } from "react";
import styled from "styled-components";
import { colors, font, media, fontSizing, spacing } from "../theme";
import Paragraph from "../common/Paragraph";
import Button from "../common/Button";
import { Caret } from "../common/icons";
import Link from "../utils/Link";
import { RouteName, RouteParams } from "../../lib/routes";
import GlobalContext from "../context/GlobalContext";
import Content from "../common/Content";
import A from "../common/A";
import { DOWNLOAD_TYPES } from "../downloadCenter/DownloadCenterPage";
import Grid, { generateAutoPlacementForIE } from "../common/Grid";
import { CollapsibleContainer } from "../CollapsibleContainer";
import Headline from "../common/Headline";

const GridContent = styled(Content.withComponent("nav"))`
  display:-ms-grid;
  display: grid;
  color: ${colors.blue};
  font-weight: ${font.weightMedium};
  grid-gap: calc(4px * 8);
  grid-template-columns: 1fr 1fr 1fr;
  ${p => generateAutoPlacementForIE(p.children, "1fr 1fr 1fr", 4 * 8)}

  ${spacing("margin-bottom", 160)}
  @media screen and ${media.wide} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    ${p => generateAutoPlacementForIE(p.children, "1fr 1fr 1fr 1fr", 4 * 8)}
  }
  @media screen and ${media.tablet} {
    grid-template-columns: 1fr 1fr;
    ${p => generateAutoPlacementForIE(p.children, "1fr 1fr", 4 * 8)}
    margin-bottom: calc(4px * 24);
  }
  @media screen and ${media.mobile} {
    grid-template-columns: 1fr;
    ${p => generateAutoPlacementForIE(p.children, "1fr", 4 * 8)}
  }
`;

const NavGroup = styled.div`
  display: block;
`;

const StyledCaret = styled(({ switchRight, ...props }) => <Caret {...props} />)`
  color: ${colors.blueDark};

  transform: ${props =>
    props.switchRight ? "rotate(-90deg)" : "translateY(2px)"};
  width: 10px;
`;

const StyledLogo = styled.img`
  // TODO: difficult to align this properly with the other images that have internal visual padding, improve this later
  height: 90%;
  background: ${colors.white};
`;

const StyledNavTitle = styled(A)`
  display: flex;
  padding-bottom: 4px;
  align-items: center;
  gap: 12px;
  border-bottom: 4px solid ${colors.pigeonSuperLight};
  ${fontSizing("footer", 24)}

  &:hover {
    text-decoration: none !important;
  }

  @media screen and ${media.superTinyZoom125} {
    height: initial;
  }
`;
const SimpleNavTitle = styled(StyledNavTitle.withComponent("h3"))`
  margin: 0;
  font-weight: 500;
`;

const NavTitleText = styled.span`
  flex: 1;
  text-align: left;
`;

type NavProps = {
  children?: string;
} & ({ href: string } | { route: RouteName; params?: RouteParams });

const LinkedNavTitle = ({ children, ...p }: NavProps) => (
  <Headline size={1} level={3} noMargin>
    <Link {...p}>
      <StyledNavTitle tabIndex={0}>
        <NavTitleText>{children}</NavTitleText>
        <StyledCaret switchRight />
      </StyledNavTitle>
    </Link>
  </Headline>
);

const NavTitle = ({ children }: NavProps) => (
  <Headline size={1} level={3} noMargin as={"span"}>
    <StyledNavTitle as={"span"}>
      <NavTitleText>{children}</NavTitleText>
      <span>
        <StyledCaret />
      </span>
    </StyledNavTitle>
  </Headline>
);

const NavList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const StyledNavItem = styled.li`
  display: flex;
  align-items: center;
  border-bottom: 2px solid ${colors.pigeonSuperLight};
  height: 4em;
  ${fontSizing("footer", 16)}
`;

const NavItem = ({ children, ...p }: NavProps) => (
  <StyledNavItem>
    <Link {...p}>
      <A tabIndex={0}>{children}</A>
    </Link>
  </StyledNavItem>
);

const SitemapNavGroup = styled.div`
  grid-column: 1/3;
  padding: 0;
  @media screen and ${media.tablet} {
    grid-column: 1/2;
  }
`;

const SitemapNavList = styled(NavList)`
  display: -ms-grid;
  ${p => generateAutoPlacementForIE(p.children, "1fr", 0)}
  @supports (display:grid) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: calc(4px * 8);
    @media screen and ${media.wide} {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media screen and ${media.tablet} {
      grid-template-columns: 1fr;
    }
  }
`;

const NavParagraph = styled(Paragraph)`
  ${spacing("padding-top", 24)}
  ${spacing("margin-bottom", 24)}
  font-weight: ${font.weightRegular};
  ${fontSizing("footer", 16)}
`;

const FoerderNavParagraph = styled(NavParagraph)`
  /* color: ${colors.pigeonLight}; */
`;
const ImageBox = styled.div`
  width: 100%;
  .eenLogo {
    @media screen and ${media.mobile} {
      width: 181px;
    }
  }

  .fundLogo {
    @media screen and ${media.mobile} {
      width: 114px;
    }
  }
  .flagLogo {
    @media screen and ${media.mobile} {
      width: 95px;
    }
  }
`;

const Navigation = () => {
  const global = useContext(GlobalContext);
  return (
    <GridContent padded aria-label={"Weitere Inhalte"}>
      {global.segments.items.map(item => (
        <NavGroup key={item.name}>
          {item.categoryClusters && (
            <CollapsibleContainer
              header={
                <NavTitle route={"segment"} params={{ segment: item.slug }}>
                  {item.name}
                </NavTitle>
              }
            >
              <NavList>
                <NavItem
                  key={item.slug}
                  route="segment"
                  params={{
                    segment: item.slug
                  }}
                >
                  {item.name}
                </NavItem>
                {item.categoryClusters.map(cluster => {
                  return (
                    <NavItem
                      key={cluster.slug}
                      route="categoryCluster"
                      params={{
                        segment: item.slug,
                        categoryCluster: cluster.slug
                      }}
                    >
                      {cluster.name}
                    </NavItem>
                  );
                })}
              </NavList>
            </CollapsibleContainer>
          )}
          {!item.categoryClusters && (
            <LinkedNavTitle route={"segment"} params={{ segment: item.slug }}>
              {item.name}
            </LinkedNavTitle>
          )}
        </NavGroup>
      ))}
      <NavGroup>
        <LinkedNavTitle route="magazin">Magazin</LinkedNavTitle>
        {/*<NavTitle route="partners">Partner</NavTitle>*/}
      </NavGroup>
      <NavGroup>
        <LinkedNavTitle route="contact">Kontakt</LinkedNavTitle>
      </NavGroup>
      <SitemapNavGroup>
        <SimpleNavTitle>Die IFB Hamburg</SimpleNavTitle>
        <SitemapNavList>
          {global.ifbNav.map(({ name, ...navProps }, idx) => (
            <NavItem key={name + idx} {...navProps}>
              {name}
            </NavItem>
          ))}
        </SitemapNavList>
      </SitemapNavGroup>
      <NavGroup>
        <LinkedNavTitle route="downloads">Downloads</LinkedNavTitle>
        <NavList>
          {DOWNLOAD_TYPES.map((d, i) => (
            <NavItem key={i} route="downloads" params={{ tab: d.id }}>
              {d.label}
            </NavItem>
          ))}
        </NavList>
      </NavGroup>

      <NavGroup>
        <LinkedNavTitle route="newsletterSubscribe">Newsletter</LinkedNavTitle>
        <NavParagraph small>
          Wir bieten Ihnen Themen-Newsletter speziell zugeschnitten zu Ihrem
          Interessensgebiet an. Melden Sie sich gleich jetzt an:
        </NavParagraph>
        <Button small ghost route="newsletterSubscribe">
          Jetzt anmelden
        </Button>
      </NavGroup>
      <NavGroup>
        <LinkedNavTitle route="euFunding">
          {global.global.footer.eu.headline}
        </LinkedNavTitle>
        <FoerderNavParagraph>
          {global.global.footer.eu.text}
        </FoerderNavParagraph>
        <Grid rowGap={media.mobile ? "24px" : ""} columns={3} mobileColumns={2}>
          <ImageBox>
            <StyledLogo
              className="eenLogo"
              src="/static/een-logo.svg"
              alt="Enterprise Europe Network Logo"
              lang="en"
            />
          </ImageBox>
          <ImageBox>
            <StyledLogo
              className="fundLogo"
              src="/static/EuInvestmentFond_0.svg"
              alt="European Investment Fund Logo"
              lang="en"
            />
          </ImageBox>
          <ImageBox>
            <StyledLogo
              className="flagLogo"
              src="/static/EUflag_0.svg"
              alt="Europäische Union Logo"
            />
          </ImageBox>
        </Grid>
      </NavGroup>
    </GridContent>
  );
};

export default Navigation;
