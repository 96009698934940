import { RouteName, RouteParams } from "../../lib/routes";
import { FlyOutOptions } from "./index";
import { GlobalContextType } from "../context/GlobalContext";

export default (p: FlyOutOptions, global: GlobalContextType) => {
  let data: ({ name: string; description?: string } & (
    | { route: RouteName; params: RouteParams }
    | { href: string }))[] = [];
  if (p.type === "custom") {
    data = global.ifbNav;
  }
  if (p.type === "segment") {
    const segment = global.segments.items.find(seg => seg._id === p.id);
    data = [
      {
        name: segment.name,
        description: segment.menuDescription,
        route: "segment",
        params: { segment: segment.slug }
      },
      ...(segment.categoryClusters && segment.categoryClusters.length > 0
        ? segment.categoryClusters
            .map(cluster => {
              return {
                name: cluster.name,
                route: "categoryCluster" as RouteName,
                params: {
                  segment: segment.slug,
                  categoryCluster: cluster.slug
                },
                description: cluster.menuDescription
              };
            })
            .filter(Boolean)
        : [])
    ];
  }
  return data;
};
