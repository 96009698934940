import React, { useState, useEffect, useContext } from "react";
import CheckboxFilter from "../common/CheckboxFilter";
import { Router, RouteParams } from "../../lib/routes";
import { stringify } from "qs";
import GlobalContext from "../context/GlobalContext";

interface SegmentCheckbox {
  name: string;
  checked: boolean;
}

interface Props {
  checkedSegments: string[];
  lightTheme?: boolean;
  onChange?: (c: string[]) => void;
}
export default function Filter(props: Props) {
  const globalContext = useContext(GlobalContext);
  const allSegments = globalContext.segments.items.map(({ name, _id: id }) => ({
    name,
    id
  }));

  const createInitialSegmentState = () =>
    allSegments.map(s => ({
      name: s.name,
      checked: Object.values(props.checkedSegments || {}).includes(s.id)
    }));

  const [segments, setSegments] = useState(createInitialSegmentState());

  const handleCheckChange = (e: SegmentCheckbox) => {
    setSegments(
      segments.map(({ name, checked }) => ({
        name,
        checked: name === e.name ? e.checked : checked
      }))
    );
  };

  useEffect(() => {
    setSegments(createInitialSegmentState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(props.checkedSegments || [false])]);

  useEffect(() => {
    const checkedValues = allSegments
      .filter(s => segments.find(s2 => s.name === s2.name && s2.checked))
      .map(s => s.id);

    if (props.onChange) {
      return props.onChange(checkedValues);
    }

    function setCheckedValues() {
      const { segments: segmentFromQuery, page, ...query } = Router.query;

      Router.replaceRoute(Router.route.replace("/", ""), {
        ...query,
        segments: stringify(checkedValues),
        page
      } as RouteParams);
    }

    setCheckedValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segments]);

  return (
    <CheckboxFilter
      label="Filtern nach"
      values={segments}
      onChange={handleCheckChange}
      lightTheme={props.lightTheme}
    />
  );
}
