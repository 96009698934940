import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { colors, font, media, THEME_SCALE, fontSizing } from "../theme";
import { Mail, MenuBars } from "../common/icons";
import Link from "../utils/Link";
import BookmarksContext from "../bookmarks/BookmarksProvider";
import SearchBar from "./SearchBar";
import { MOBILE_ZOOM125_HEIGHT } from "./constants";
import { Sprache, Nachhaltigkeit } from "../common/icons";

const link = css`
  display: inline-flex;
  align-items: center;
  text-decoration: none;
`;

const StyledInteractionMenu = styled.nav`
  height: ${80 * THEME_SCALE}px;
  color: ${colors.darkgrey};
  display: flex;
  ${fontSizing("header", 22)}
  @media screen and ${media.tablet} {
    height: 60px;
  }
  @media print {
    display: none;
  }

  @media screen and ${media.superTinyZoom125} {
    height: ${MOBILE_ZOOM125_HEIGHT}px;
    flex: 1;
    justify-content: space-between;
  }
`;

const SpracheIcon = styled(Sprache)`
  margin-right: calc(4px * 4);
`;

const NachhaltigkeitIcon = styled(Nachhaltigkeit)`
  margin-right: calc(4px * 4);
`;

export const StyledBookmarkCounter = styled.span<{ hide?: boolean }>`
  display: inline-block;
  background: ${colors.blueDark};
  margin-right: calc(4px * 4);
  min-width: ${32 * THEME_SCALE}px;
  padding: 0 ${8 * THEME_SCALE}px;
  height: ${32 * THEME_SCALE}px;
  border-radius: 32px;
  font-size: ${16 * THEME_SCALE}px;
  font-weight: ${font.weightBlack};
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${p => p.hide && "display: none;"}
`;

const StyledLink = styled.a`
  color: ${colors.blueDark};
  padding: 0 calc(4px * 4);
  font-weight: ${font.weightMedium};

  ${link}
  @media screen and ${media.tablet}{
    display:none;
  }

  &:hover,
  &:active {
    color: ${colors.calypso};

    ${StyledBookmarkCounter} {
        background-color: ${colors.calypso};
    }
  }

  &:disabled {
    color: ${colors.alto};
    ${StyledBookmarkCounter} {
        background-color: ${colors.alto};
    }
  }
`;

const ContactButton = styled.a`
  ${link}
  height: 100%;
  color: white;
  padding: 0 calc(4px * 8 * ${THEME_SCALE});
  margin-left: calc(4px * 3);
  background: ${colors.blue};

  @media screen and ${media.tablet} {
    padding: 0 calc(4px * 4);
    > span {
      display: none;
    }
  }

  @media screen and ${media.superTinyZoom125} {
    margin-left: 0;
    padding: 4px;
    flex: 1;
  }
`;
export const MenuButton = styled(ContactButton)`
  color: inherit;
  background: none;
  margin: 0;
  display: none;
  @media screen and ${media.tablet} {
    display: inline-flex;
  }

  @media screen and ${media.superTinyZoom125} {
    padding: 0;
    flex: 1;
    justify-content: center;
  }
`;

const StyledMail = styled(Mail)`
  margin-right: calc(4px * 2);
  width: 16.5px;
  height: auto;
  @media screen and ${media.tablet} {
    margin: 0 auto;
  }
`;

const InteractionMenu = (p: {
  toggleMobileMenu: (e: React.MouseEvent) => void;
}) => {
  const bookmarks = useContext(BookmarksContext);
  const bookmarkCount =
    bookmarks.bookmarkedDownloads.length + bookmarks.bookmarkedArticles.length;
  return (
    <StyledInteractionMenu aria-label={"Service-Navigation"}>
      <SearchBar />
      <Link route="downloads">
        <StyledLink>Downloads</StyledLink>
      </Link>
      <Link route="bookmarks">
        <StyledLink>
          <StyledBookmarkCounter
            hide={bookmarks.isLoading || bookmarkCount <= 0}
          >
            {bookmarkCount}
          </StyledBookmarkCounter>
          Merkliste
        </StyledLink>
      </Link>
      <Link href="/nachhaltigkeit">
        <StyledLink href="/nachhaltigkeit">
          <NachhaltigkeitIcon
            height={25 * THEME_SCALE}
            width={25 * THEME_SCALE}
          />
          Nachhaltigkeit
        </StyledLink>
      </Link>
      {/*<Link route="partners">
        <StyledLink>Partner</StyledLink>
      </Link>*/}
      <Link href="/g/information-in-leichter-sprache">
        <StyledLink href="/g/information-in-leichter-sprache">
          <SpracheIcon height={25 * THEME_SCALE} width={25 * THEME_SCALE} />
          Leichte Sprache
        </StyledLink>
      </Link>
      <Link route="contact">
        <ContactButton>
          <StyledMail ariaHidden="true" />
          <span>Kontakt</span>
        </ContactButton>
      </Link>
      <MenuButton
        onClick={e => {
          e.preventDefault();
          p.toggleMobileMenu(e);
        }}
        href="#"
        aria-label="Menü öffnen"
      >
        <MenuBars />
      </MenuButton>
    </StyledInteractionMenu>
  );
};

export default InteractionMenu;
