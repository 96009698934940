import styled from "styled-components";
import DefaultPagination from "../common/Pagination";
import { spacing } from "../theme";
import { DownloadProps } from "../common/Downloads";

export class DPagination extends DefaultPagination<DownloadProps> {}
export const DownloadPagination = styled(DPagination)`
  ${spacing("margin-bottom", 64)}
`;

export default DownloadPagination;
