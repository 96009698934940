import React, { Component, createElement, ReactNode } from "react";
import { Router, pushRouteWithQuery, RouteName } from "../../../lib/routes";
import Controls from "./Controls";

interface Props<T> {
  page: number;
  total: number;
  items: T[];
  renderItem: (item: T, idx: number) => ReactNode | ReactNode[];
  renderEmpty: () => ReactNode | ReactNode[];
  loadItems?: (page: number) => void;
  pageSize?: number;
  scrollOffset?: number;
  className?: string;
  name: string;
  wrapperTag?: keyof JSX.IntrinsicElements;
}
export default class Pagination<T = {}> extends Component<Props<T>> {
  static defaultPageSize = 10;
  static parsePageToQuery(
    page: number,
    pageSize: number = Pagination.defaultPageSize
  ): { offset: number; limit: number } {
    let offset = 0;
    if (page && page > 0) {
      offset = page * pageSize;
    }
    return { offset, limit: pageSize };
  }
  ref = React.createRef<HTMLElement>();

  loadItems = (page: number) => {
    if (this.props.loadItems) {
      this.props.loadItems(page);
      if (this.ref) {
        window.scrollTo({
          top: this.ref.current.offsetTop - (this.props.scrollOffset || 0),
          behavior: "smooth"
        });
      }
      return;
    }

    const query = {
      ...Router.query,
      page: page === 0 ? undefined : page + 1
    };
    pushRouteWithQuery(Router.route.replace("/", "") as RouteName, query);
  };

  render() {
    const pageSize = this.props.pageSize || Pagination.defaultPageSize;
    const pages = Array(Math.ceil(this.props.total / pageSize)).fill(0);
    const activePage = this.props.page;

    return createElement(
      this.props.wrapperTag || "div",
      { ref: this.ref, className: this.props.className },
      <>
        {this.props.items.length > 0
          ? this.props.items.slice(0, pageSize).map((item, idx) => {
              return this.props.renderItem(item, idx);
            })
          : !!this.props.renderEmpty && this.props.renderEmpty()}
        {pages.length > 1 && (
          <Controls
            pages={pages.length}
            index={activePage}
            goto={this.loadItems}
            prev={() => this.loadItems(activePage - 1)}
            next={() => this.loadItems(activePage + 1)}
            name={this.props.name}
          />
        )}
      </>
    );
  }
}
