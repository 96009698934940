export interface PageImages {
  _pageImages?: { credit?: string }[];
}
export function getImageCredits(pageProps: PageImages) {
  if (!("_pageImages" in pageProps)) return [];

  const images = pageProps._pageImages;
  if (!images) return [];

  const credits = images.map(i => i && i.credit).filter(Boolean);

  return credits;
}
