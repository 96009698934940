// NOTE: This require will be replaced with `@sentry/browser` when
// process.browser === true thanks to the webpack config in next.config.js
import * as Sentry from "@sentry/node";
import pkg from "../package.json";

const isLive = process.env.NODE_ENV === "production";

export default function() {
  const sentryOptions = {
    dsn:
      "https://ad921655529c45659b3cca05fc4d5684:59a4e33be8c64ccab800ce42f64e2087@sentry.cellular.de/16",
    release: pkg.version,
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    enabled: isLive
  };

  Sentry.init(sentryOptions);

  return {
    Sentry,
    captureException: (err, ctx) => {
      if (process.env.NODE_ENV === "development") throw err;
      Sentry.configureScope(scope => {
        if (err.message) {
          // De-duplication currently doesn't work correctly for SSR / browser errors
          // so we force deduplication by error message if it is present
          scope.setFingerprint([err.message]);
        }

        if (err.statusCode) {
          scope.setExtra("statusCode", err.statusCode);
        }

        if (ctx) {
          const { req, res, errorInfo, query, pathname } = ctx;

          if (res && res.statusCode) {
            scope.setExtra("statusCode", res.statusCode);
          }

          if ((process as any).browser) {
            scope.setTag("ssr", "false");
            scope.setExtra("query", query);
            scope.setExtra("pathname", pathname);
          } else {
            scope.setTag("ssr", "true");
            scope.setExtra("url", req.url);
            scope.setExtra("method", req.method);
            scope.setExtra("headers", req.headers);
            scope.setExtra("params", req.params);
            scope.setExtra("query", req.query);
          }

          if (errorInfo) {
            Object.keys(errorInfo).forEach(key =>
              scope.setExtra(key, errorInfo[key])
            );
          }
        }
      });

      return Sentry.captureException(err);
    }
  };
}
