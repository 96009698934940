import styled from "styled-components";
import { font, colors, media, spacing } from "../../theme";

const Container = styled.ul`
  display: inline;
  @media ${media.mobile} {
    display: none;
  }
  list-style: none;
  padding: 0;
  > li {
    display: inline-block;
  }
`;
const Page = styled.button<{ active?: boolean }>`
  padding: 0 ${p => p.active ? 0 : "8px"};
  width: auto;
  background: transparent;
  font-family: ${font.main};
  font-size: 20px;
  font-weight: ${p => (p.active ? 600 : "normal")};
  line-height: 22px;
  height: 32px;
  width: ${p => (p.active ? "32px" : "")} ;
  ${spacing("margin-left", 24)}
  ${spacing("margin-right", 24)}
  border: none;
  box-sizing: content-box;
  cursor: pointer;
  @media ${media.tiny} {
    margin: 0 10px;
    font-size: 16px;
    padding: 0 0;
  }
  background-size: ${p => (p.active ? "100% 1px" : "0 1px")};
  color: ${p => (p.active ? colors.white : colors.blueDark)};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  background-color: ${p => p.active ? colors.calypso : ""};
  border-radius: ${p => p.active ? "4px" : ""};
`;

const Bullets = styled.li`
  color: lightgray;
  white-space: pre;
  display: inline;
  @media ${media.mobile} {
    font-size: 16px;
  }
  @media ${media.tiny} {
    font-size: 12px;
  }
`;
function createPage(number, activeIndex, goto) {
  const isActive = activeIndex === number - 1;
  return (
    <li key={number}>
      <Page
        active={isActive}
        tabIndex={0}
        onClick={() => goto(number - 1)}
        aria-current={isActive ? "page" : undefined}
        aria-label={isActive ? "Aktuelle Seite" : `Gehe zu Seite ${number}`}
      >
        {number}
      </Page>
    </li>
  );
}

function generateSequence(from, to) {
  return Array.from({ length: to - from + 1 }, (v, k) => k + from);
}

interface Props {
  pages: number;
  index: number;
  goto: (i: number) => void;
}

export default function Numbers({ pages, index, goto }: Props) {
  if (pages <= 1) return null;
  let view;
  if (pages > 10) {
    const bullets = key => (
      <Bullets key={key} aria-hidden="true">
        {" "}
        &bull;&bull;&bull;{" "}
      </Bullets>
    );
    const shore = 3; // pages shown, if index is at left or right end
    const atSea = 5; // page offset at which shore transforms into bullets
    if (index + 1 < atSea) {
      view = [
        generateSequence(1, Math.max(shore, index + 2)).map(number =>
          createPage(number, index, goto)
        ),
        bullets("b"),
        createPage(pages, index, goto)
      ];
    } else if (index + 1 <= pages - atSea + 1) {
      view = [
        createPage(1, index, goto),
        bullets("b1"),
        generateSequence(index, index + 2).map(number =>
          createPage(number, index, goto)
        ),
        bullets("b2"),
        createPage(pages, index, goto)
      ];
    } else {
      view = [
        createPage(1, index, goto),
        bullets("b"),
        generateSequence(Math.min(index, pages - shore + 1), pages).map(
          number => createPage(number, index, goto)
        )
      ];
    }
  } else {
    const pageArray = Array.from({ length: pages }, (v, k) => k + 1);
    view = pageArray.map(el => createPage(el, index, goto));
  }
  return <Container>{view}</Container>;
}
