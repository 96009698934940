import React from "react";
import Content from "./common/Content";
import Headline from "./common/Headline";
import styled from "styled-components";
import { spacing } from "./theme";

interface Props {
  credits: string[];
}
const FooterContent = styled(Content.withComponent("footer"))`
  ${spacing("margin-bottom", 40)}
  ${spacing("margin-top", 40)}
`;

const Ul = styled.ul`
  margin: 0;
  padding-inline-start: 20px;
`;
const LI = styled.li`
  font-size: 10px;
  font-style: italic;
`;
export default function Credits({ credits }: Props) {
  if (!credits || !credits.length) return null;

  return (
    <FooterContent padded>
      <Headline size={6} level={2}>
        Bild Credits:
      </Headline>
      <Ul>
        {credits.map((c, idx) => (
          <LI key={idx}>{c}</LI>
        ))}
      </Ul>
    </FooterContent>
  );
}
