import React, { Fragment, ReactNode, useContext, useState } from "react";
import styled, { css } from "styled-components";
import { colors, media, fontSizing } from "../theme";
import { RoundedArrow } from "../common/icons";
import GlobalContext from "../context/GlobalContext";
import { RouteName, RouteParams } from "../../lib/routes";
import Link from "../utils/Link";
import { FlyOutOptions } from "./index";
import FlyOut from "./FlyOut";

// TODO: check out why we use any @joachim
const FlyoutNav = styled.ul<any>`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;
const SizedNav = styled(FlyoutNav)`
  display: block;
  flex: 1;
  > li {
    max-width: ${p => 100 / Math.max(React.Children.count(p.children), 1)}%;
    float: left;
  }
`;

const StyledFlyoutItem = styled.a<{ right?: boolean; grey?: boolean }>`
  text-decoration: none;
  color: ${colors.blue};
  font-weight: 500;
  ${fontSizing("header", 22)}
  padding-right: calc(4px * 8);
  display: inline-flex;
  display: flex;
  align-items: center;
  width: 100%;
  &:hover,
  &:focus {
    color: ${colors.calypso};
  }
  > span {
    overflow: hidden;
  }
  ${p =>
    p.right &&
    css`
      padding: 0;
      justify-content: space-between;
    `}
  ${p =>
    p.grey &&
    css`
      color: ${colors.pigeonLight};
      &:hover,
      &:focus {
        color: ${colors.pigeonLight};
      }
    `}
`;

const ArrowWrapper = styled.div`
  margin-left: 12px;
`;

type FlyoutProps = {
  single?: boolean;
  right?: boolean;
  grey?: boolean;
  children: ReactNode | ReactNode[];
  className?: string;
  menuIsExpanded?: boolean;
  onClick?: (e: React.MouseEvent) => void;
} & (
  | {
      route: RouteName;
      params?: RouteParams;
    }
  | { href: string });

export const FlyoutNavItem = (props: FlyoutProps) => (
  <li className={props.className}>
    <FlyoutNavItemLink {...props} />
  </li>
);

export const FlyoutNavItemLink = ({
  children,
  single,
  onClick,
  right,
  grey,
  menuIsExpanded,
  ...p
}: FlyoutProps) => (
  <Link {...p}>
    <StyledFlyoutItem
      role="button"
      aria-haspopup={onClick ? "menu" : "false"}
      aria-expanded={menuIsExpanded}
      onClick={onClick}
      right={right}
      grey={grey}
    >
      {children}
      {!single && (
        <ArrowWrapper>
          <RoundedArrow orientation={right ? "right" : "down"} />
        </ArrowWrapper>
      )}
    </StyledFlyoutItem>
  </Link>
);

const Nav = styled.nav`
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: ${colors.blue};
  @media screen and ${media.tablet} {
    display: none;
  }
  @media print {
    display: none;
  }
`;

const NavBar = (p: {
  setFlyout: (options: FlyOutOptions) => void;
  closeFlyout: () => void;
  currentFlyOut: FlyOutOptions;
  openFlyoutId?: string;
  mobileOpen: boolean;
}) => {
  const global = useContext(GlobalContext);
  return (
    <Nav aria-label={"Hauptnavigation"}>
      <SizedNav>
        {global.segments.items.map(segment => (
          <li key={segment.slug}>
            <FlyoutNavItemLink
              href="#"
              menuIsExpanded={p.openFlyoutId === segment._id}
              onClick={e => {
                e.preventDefault();
                //updateFlyout({ type: "segment", id: segment._id });
                p.setFlyout({ type: "segment", id: segment._id });
              }}
            >
              <span>{segment.name}</span>
            </FlyoutNavItemLink>
            {!p.mobileOpen &&
              p.currentFlyOut &&
              "type" in p.currentFlyOut &&
              p.currentFlyOut.id === segment._id && (
                <FlyOut {...p.currentFlyOut} close={p.closeFlyout} />
              )}
          </li>
        ))}
      </SizedNav>

      <FlyoutNav>
        <li>
          <FlyoutNavItemLink
            href="#"
            menuIsExpanded={p.openFlyoutId === "ifb-hamburg"}
            onClick={e => {
              e.preventDefault();
              p.setFlyout({ type: "custom", id: "ifb-hamburg" });
            }}
          >
            <span>Die IFB Hamburg</span>
          </FlyoutNavItemLink>
          {!p.mobileOpen &&
            p.currentFlyOut &&
            "type" in p.currentFlyOut &&
            p.currentFlyOut.id === "ifb-hamburg" && (
              <FlyOut {...p.currentFlyOut} close={p.closeFlyout} />
            )}
        </li>
        <FlyoutNavItem single route="magazin">
          <span>Magazin</span>
        </FlyoutNavItem>
      </FlyoutNav>
    </Nav>
  );
};

export default NavBar;
