/**
 * https://github.com/brigade/delayed-scroll-restoration-polyfill
 *
 * Refactored, Edited, improved to listen on document Height, and cancel restoration on scroll
 *
 *
 */
const getScrollbarWidth = () => {
  const outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.width = "100px";
  outer.style.msOverflowStyle = "scrollbar";

  document.body.appendChild(outer);

  const widthNoScroll = outer.offsetWidth;
  // force scrollbars
  outer.style.overflow = "scroll";

  // add innerdiv
  const inner = document.createElement("div");
  inner.style.width = "100%";
  outer.appendChild(inner);

  const widthWithScroll = inner.offsetWidth;

  // remove divs
  outer.parentNode.removeChild(outer);

  return widthNoScroll - widthWithScroll;
};
const delayedScrollRestoration = () => {
  if (window.history.pushState) {
    history.scrollRestoration = "manual"; // Disable native Scroll Restoration Implementation
    const SCROLL_RESTORATION_TIMEOUT_MS = 3000;
    const TRY_TO_SCROLL_INTERVAL_MS = 50;

    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    // Store current scroll position in current state when navigating away.
    window.history.pushState = function(...args) {
      const body = document.body;
      const html = document.documentElement;
      const documentWidth = Math.max(
        body.scrollWidth,
        body.offsetWidth,
        html.clientWidth,
        html.scrollWidth,
        html.offsetWidth
      );
      const documentHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      const newStateOfCurrentPage = {
        ...window.history.state,
        __scrollX: window.scrollX,
        __scrollY: window.scrollY,
        __height: documentHeight,
        __width: documentWidth
      };
      originalReplaceState.call(window.history, newStateOfCurrentPage, "");

      originalPushState.apply(window.history, args);
    };

    // Make sure we don't throw away scroll position when calling "replaceState".
    window.history.replaceState = function(state, ...otherArgs) {
      const newState = {
        __scrollX: window.history.state && window.history.state.__scrollX,
        __scrollY: window.history.state && window.history.state.__scrollY,
        __height: window.history.state && window.history.state.__height,
        __width: window.history.state && window.history.state.__width,
        ...state
      };

      originalReplaceState.apply(window.history, [newState].concat(otherArgs));
    };

    let timeoutHandle = null;
    let scrollBarWidth = null;

    const cancelRestore = () => {
      clearTimeout(timeoutHandle);
      window.removeEventListener("scroll", cancelRestore);
    };

    // Try to scroll to the scrollTarget, but only if we can actually scroll
    // there. Otherwise keep trying until we time out, then scroll as far as
    // we can.
    const tryToScrollTo = scrollTarget => {
      // Stop any previous calls to "tryToScrollTo".
      clearTimeout(timeoutHandle);

      const body = document.body;
      const html = document.documentElement;
      if (!scrollBarWidth) {
        scrollBarWidth = getScrollbarWidth();
      }

      const documentWidth = Math.max(
        body.scrollWidth,
        body.offsetWidth,
        html.clientWidth,
        html.scrollWidth,
        html.offsetWidth
      );
      const documentHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      if (
        documentWidth + scrollBarWidth - window.innerWidth >= scrollTarget.x &&
        documentHeight + scrollBarWidth - window.innerHeight >=
          scrollTarget.y &&
        documentHeight == scrollTarget.h &&
        documentWidth == scrollTarget.w
      ) {
        window.scrollTo(scrollTarget.x, scrollTarget.y);
        setTimeout(() => {
          window.dispatchEvent(new Event("scroll")); //Update JS Parallax Elements
        }, 50);
      } else if (Date.now() > scrollTarget.latestTimeToTry) {
        window.scrollTo(0, 0);
      } else {
        timeoutHandle = setTimeout(
          () => tryToScrollTo(scrollTarget),
          TRY_TO_SCROLL_INTERVAL_MS
        );
      }
    };

    // Try scrolling to the previous scroll position on popstate
    const onPopState = () => {
      const state = window.history.state;
      if (
        state &&
        Number.isFinite(state.__scrollX) &&
        Number.isFinite(state.__scrollY)
      ) {
        //window.addEventListener("scroll", cancelRestore);
        setTimeout(() =>
          tryToScrollTo({
            x: state.__scrollX,
            y: state.__scrollY,
            h: state.__height,
            w: state.__width,
            latestTimeToTry: Date.now() + SCROLL_RESTORATION_TIMEOUT_MS
          })
        );
      }
    };

    window.addEventListener("popstate", onPopState, true);
  }
};
export default delayedScrollRestoration;
