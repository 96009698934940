import React from "react";
import styled from "styled-components";
import DefaultHeadline from "../common/Headline";
import { media, gradientBackground } from "../theme";
import Content from "../common/Content";
import { TAB_HEIGHT } from "../common/tabs";
import DefaultBreadcrumbs from "../common/TopBox/Breadcrumbs";

const Breadcrumbs = styled(DefaultBreadcrumbs)`
  @media ${media.mobile} {
    display: none;
  }
`;

const Headline = styled(DefaultHeadline)`
  margin-top: 0.6em;
`;
const Container = styled.header`
  ${gradientBackground};
  padding-bottom: ${TAB_HEIGHT}px;
  @media ${media.mobile} {
    padding-bottom: 0;
  }
`;

const Header = () => {
  return (
    <Container>
      <Content padded>
        <Breadcrumbs
          path={[{ route: "downloads", title: "Downloads" }]}
          white
        />
        <Headline white level={1}>
          Downloads
        </Headline>
      </Content>
    </Container>
  );
};

export default React.memo(Header);
