import styled from "styled-components";
import { TabList as DefaultTabList, Tab as DefaultTab } from "@reach/tabs";
import { colors, breakpoints, media, maxWidth, THEME_SCALE } from "../../theme";
import { FOCUS_CLASS_NAME } from "../../GlobalStyle";
export const TAB_HEIGHT = 72 * THEME_SCALE;

export const Tab = styled(DefaultTab)`
  -webkit-appearance: button;
  text-transform: none;
  overflow: hidden;
  font-family: inherit;
  font-size: 18px;
  min-height: ${TAB_HEIGHT}px;
  margin: 0;
  padding: 0;
  flex: 1;
  text-align: center;
  color: ${colors.blue};
  border: 1px solid ${colors.bluegrey};
  border-left: none;
  cursor: ${p => (!p.disabled ? "pointer" : "auto")};
  background: ${colors.anotherblue};
  &:first-child {
    border-left: 1px solid ${colors.bluegrey};
  }
  &[aria-selected="true"] {
    background: ${colors.white};
  }
  .${FOCUS_CLASS_NAME} :focus {
    position: relative;
    outline: 2px solid ${colors.blue};
    border-color: white;
    z-index: 1;
  }
  @media ${media.mobile} {
    box-sizing: border-box;
    text-align: left;
    padding: 0 4vw 0 4vw;
    width: 100%;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 2px solid ${colors.white};
    &:first-child {
      border-left: none;
    }
    &:last-child {
      border-bottom: 2px solid ${colors.white};
    }
  }
`;

export const TabList = styled(DefaultTabList)`
  display: flex;
  flex-direction: row;
  min-height: ${TAB_HEIGHT}px;
  max-width: ${breakpoints.wide}px;
  margin: auto;
  @media (max-width: ${maxWidth.page + 80}px) {
    padding: 0 8vw 0 8vw;
  }

  @media ${media.mobile} {
    padding: 0;
    flex-direction: column;
    justify-content: flex-start;
  }
`;
