import React, { Fragment, useState, useContext } from "react";
import { DownloadProps } from "../common/Downloads";
import { DownloadPagination } from "./Pagination";
import GlobalContext from "../context/GlobalContext";
import { HEIGHT, MOBILE_HEIGHT } from "../Header/constants";
import { Entry } from "../bookmarks/BookmarkPage";

export const PAGE_SIZE = 10;

export default function GuidelineTab(props: {
  downloads: DownloadProps[];
  name: string;
}) {
  const [page, setPage] = useState(0);
  const {
    theme: { isMobile }
  } = useContext(GlobalContext);
  const scrollOffset = isMobile ? MOBILE_HEIGHT : HEIGHT;

  const items = props.downloads.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE);

  return (
    <Fragment>
      <DownloadPagination
        name={props.name}
        page={page}
        pageSize={PAGE_SIZE}
        total={props.downloads.length}
        items={items}
        loadItems={page => setPage(page)}
        renderItem={(d, idx) => (
          <Entry {...d} isDownload key={d.id + idx} activeText="Gemerkt" />
        )}
        scrollOffset={scrollOffset}
        renderEmpty={() => ""}
      />
    </Fragment>
  );
}
