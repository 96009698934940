import React, { useContext } from "react";
import Content from "../common/Content";
import styled from "styled-components";

import SearchBar from "./SearchBar";
import { StyledBookmarkCounter } from "./InteractionMenu";
import {
  Cross,
  Download,
  /*Partner,*/ Bookmarks,
  Phone,
  Nachhaltigkeit
} from "../common/icons";
import {
  colors,
  media,
  spacing,
  fontSizing,
  THEME_SCALE,
  font
} from "../theme";
import GlobalContext from "../context/GlobalContext";
import { FlyoutNavItem } from "./NavBar";
import { generateAutoPlacementForIE } from "../common/Grid";
import BookmarksContext from "../bookmarks/BookmarksProvider";
import { FlyOutOptions } from "./index";

export const StyledContent = styled(Content)`
  max-height: 100%;
  margin: 0;
`;
const IconWrapper = styled.span`
  ${spacing("margin-right", 24)}
  & > svg {
    height: ${24 * THEME_SCALE}px;
    width: ${24 * THEME_SCALE}px;
  }
`;
const BookmarksCounter = styled(StyledBookmarkCounter)`
  margin: 0;
`;
export const TopBar = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  color: ${colors.darkgrey};
  ${fontSizing("text", 24)};
  padding: 16px 0;
`;
export const Nav = styled.ul`
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  ${p =>
    generateAutoPlacementForIE(p.children, "1fr 1fr", {
      row: 0,
      column: 4 * 5
    })}
  grid-column-gap: calc(4px * 5);
  list-style-type: none;
  margin: 0;
  padding: 0;
  a {
    font-size: ${fontSizing("text", 24)};
    width: 100%;
  }
  span {
    width: 100%;
  }
  li {
    padding-bottom: calc(4px * 2.5);
    padding-top: calc(4px * 2.5);
    &:first-child {
      padding-top: 0;
    }
    border-bottom: 1px solid ${colors.pigeonSuperLight};
  }
  @media screen and ${media.mobile} {
    grid-template-columns: 1fr;
    ${p =>
      generateAutoPlacementForIE(p.children, "1fr", { row: 0, column: 4 * 5 })}
  }
`;
export const InvisibleLink = styled.button`
  ${fontSizing("text", 28)}
  font-family: ${font.main};
  text-decoration: none;
  color: inherit;
  padding: 0;
  border: none;
  background: none;
  flex-shrink: 0;
  width: auto;
  line-height: 1.1em;
  height: 1.1em;
  cursor: pointer;
`;
export const NavItemWithSpaceBetween = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
export const Overflow = styled.div`
  overflow: auto;
  height: 100%;
  flex-shrink: 1;
`;

const MobileNav = (p: {
  close: (e: React.MouseEvent) => void;
  setFlyout: (options: FlyOutOptions) => void;
}) => {
  const global = useContext(GlobalContext);
  const bookmarks = useContext(BookmarksContext);
  const bookmarkCount =
    bookmarks.bookmarkedDownloads.length + bookmarks.bookmarkedArticles.length;
  return (
    <StyledContent padded>
      <TopBar>
        <SearchBar mobile />
        <InvisibleLink onClick={p.close} aria-label="Menü schließen">
          <Cross />
        </InvisibleLink>
      </TopBar>
      <Overflow>
        <Nav>
          {global.segments.items.map(segment => (
            <FlyoutNavItem
              key={segment.slug}
              href="#"
              onClick={e => {
                e.preventDefault();
                p.setFlyout({ type: "segment", id: segment._id });
              }}
              right
            >
              <span>{segment.name}</span>
            </FlyoutNavItem>
          ))}
          <FlyoutNavItem
            href="#"
            onClick={e => {
              e.preventDefault();
              p.setFlyout({ type: "custom", id: "ifb-hamburg" });
            }}
            right
          >
            <span>Die IFB Hamburg</span>
          </FlyoutNavItem>
          <FlyoutNavItem single route="magazin" right>
            <span>Magazin</span>
          </FlyoutNavItem>
          <FlyoutNavItem single route="downloads" right grey>
            <div>
              <IconWrapper aria-hidden="true">
                <Download />
              </IconWrapper>
              <span>Downloads</span>
            </div>
          </FlyoutNavItem>
          <FlyoutNavItem single route="bookmarks" right grey>
            <NavItemWithSpaceBetween>
              <div>
                <span>
                  <IconWrapper aria-hidden="true">
                    <Bookmarks />
                  </IconWrapper>
                  Merkliste
                </span>
              </div>
              <BookmarksCounter
                hide={bookmarks.isLoading || bookmarkCount <= 0}
              >
                {bookmarkCount}
              </BookmarksCounter>
            </NavItemWithSpaceBetween>
          </FlyoutNavItem>
          <FlyoutNavItem single href="/nachhaltigkeit" right grey>
            <div>
              <IconWrapper aria-hidden="true">
                <Nachhaltigkeit />
              </IconWrapper>
              <span>Nachhaltigkeit</span>
            </div>
          </FlyoutNavItem>
          {/*<FlyoutNavItem single route="partners" right grey>
            <IconWrapper>
              <Partner aria-hidden="true" />
            </IconWrapper>
            Partner
          </FlyoutNavItem>*/}
          <FlyoutNavItem single route="contact" right grey>
            <span>
              <IconWrapper aria-hidden="true">
                <Phone />
              </IconWrapper>
              Kontakt
            </span>
          </FlyoutNavItem>
        </Nav>
      </Overflow>
    </StyledContent>
  );
};
export default MobileNav;
