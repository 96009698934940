import { Request } from "express";

export default function getBaseUrl(req?: Request) {
  if (req) {
    const host = req.get("Host");
    const internalHost = req.get("Internal-Host") || process.env.INTERNAL_HOST;
    const dev = process.env.NODE_ENV !== "production";
    const protocol = dev ? req.protocol : "https";

    return `${protocol}://${internalHost || host}`;
  }
  return "";
}
