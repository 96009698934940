import React from "react";
import styled from "styled-components";
import { media } from "../theme";

const AlignRight = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-wrap: wrap;
`;
export const AlignRightOnTablet = styled(AlignRight)`
  @media screen and ${media.mobile} {
    justify-content: flex-start;
  }
`;
export default AlignRight;
