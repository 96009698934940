import React, { Fragment, useContext, MouseEvent, useState } from "react";
import BookmarksContext, { Bookmark } from "../bookmarks/BookmarksProvider";
import DefaultHeadline from "../common/Headline";
import DefaultButton from "../common/Button";
import DefaultDownloads from "../common/Downloads";
import styled from "styled-components";
import { ApiResult } from "../../lib/CmsApi";
import Filter from "../search/SegmentFilter";
import { defaultMaxWithStyle } from "../common/Content";
import { FirstLevelAccordion, SecondLevelAccordion } from "../common/Accordion";
import { media } from "../theme";
import GlobalContext from "../context/GlobalContext";
import StyledSpan from "../common/StyledSpan";

const Container = styled.div`
  display: flex;
  align-items: center;
  @media ${media.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const FilterContainer = styled.div`
  height: calc(30 * 4px);
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${media.mobile} {
    justify-content: flex-start;
    align-items: center;
    margin-top: calc(4px * 7);
    height: auto;
    white-space: initial;
    word-break: break-word;
  }
  ${defaultMaxWithStyle}
`;

const Headline = styled(DefaultHeadline)`
  margin: 0;
  flex-shrink: 1;
`;

const Button = styled(DefaultButton)`
  margin-right: calc(4px * 16);
  @media ${media.mobile} {
    margin-top: calc(4px * 4);
  }

  @media screen and ${media.superTinyZoom125} {
    margin-right: 0;
    position: absolute;
  }
`;

const Downloads = styled(DefaultDownloads)`
  ul > div {
    padding: calc(4px * 5) 0;
  }
`;

const ButtonWrapper = styled.div`
  @media screen and ${media.superTinyZoom125} {
    position: relative;
    height: 60px;
    margin-top: 12px;
  }
`;

const ClusterHeader = (props: { label: string; name: string }) => {
  return (
    <Fragment>
      <StyledSpan tiny>{props.label}</StyledSpan>
      <Headline size={4} level={2}>
        {props.name}
      </Headline>
    </Fragment>
  );
};

const ProgramHeader = (props: { label: string; downloads: Bookmark[] }) => {
  const provider = useContext(BookmarksContext);
  const ids = props.downloads.map(d => d.id);

  const markAll = (e: MouseEvent) => {
    e.stopPropagation();
    provider.addDownloads(props.downloads);
  };
  const unmarkAll = (e: MouseEvent) => {
    e.stopPropagation();
    provider.removeDownloads(ids);
  };
  const allSelected = provider.allDownloadsAdded(ids);

  return (
    <Container>
      <Headline size={5} level={3}>
        {props.label}
      </Headline>
      <ButtonWrapper>
        <Button
          tiny
          wide
          ghost={!allSelected}
          onclick={allSelected ? unmarkAll : markAll}
          aria-label={allSelected ? "Alle entfernen" : "Alle merken"}
        >
          {allSelected ? "Entfernen" : "Merken"}
        </Button>
      </ButtonWrapper>
    </Container>
  );
};

interface DownloadsProps {
  segments: Props["downloads"];
}
const ProgramDownloads = (props: DownloadsProps) => (
  <Fragment>
    {Object.entries(props.segments).map(([segment, c], idx) =>
      Object.entries(c).map(([cluster, program], jdx) => {
        if (!Object.keys(program).length) return null;
        return (
          <FirstLevelAccordion
            padded
            key={cluster}
            label={<ClusterHeader label={segment} name={cluster} />}
            ariaLevel={1}
            white
            delayPanelRender={true}
            uuid={`pd_${idx}_${jdx}`}
          >
            {Object.entries(program).map(([programName, documents], hdx) => {
              const allSegmentDownloads: Bookmark[] = documents.map(d => ({
                id: d._id,
                title: d.name,
                href: d.file ? d.file._src : d._url
              }));

              return (
                <SecondLevelAccordion
                  key={programName}
                  ariaLevel={2}
                  uuid={`pd_${idx}_${jdx}_${hdx}`}
                  delayPanelRender={true}
                  label={
                    <ProgramHeader
                      label={programName}
                      downloads={allSegmentDownloads}
                    />
                  }
                >
                  <Downloads downloads={documents} padded striped />
                </SecondLevelAccordion>
              );
            })}
          </FirstLevelAccordion>
        );
      })
    )}
  </Fragment>
);

export interface Props {
  downloads: {
    [segment: string]: {
      [cluster: string]: {
        [program: string]: (ApiResult<
          "listPrograms"
        >["items"][0]["documents"][0] & {
          type: ApiResult<"listDocumentTypes">["items"][0];
        })[];
      };
    };
  };
}
export default function ProgramTab(props: Props) {
  const globalContext = useContext(GlobalContext);
  const [filters, setFilters] = useState([]);

  const allSegments = globalContext.segments.items.map(({ name, _id: id }) => ({
    name,
    id
  }));
  const { downloads } = props;

  const selectedSegments =
    filters && !!filters.length
      ? filters.reduce((acc, segment) => {
          const cluster = allSegments.find(s => s.id === segment.toString());
          if (cluster && downloads[cluster.name]) {
            acc[cluster.name] = downloads[cluster.name];
          }
          return acc;
        }, {})
      : downloads;

  return (
    <Fragment>
      <FilterContainer>
        <Filter checkedSegments={filters} onChange={setFilters} />
      </FilterContainer>
      <ProgramDownloads segments={selectedSegments} />
    </Fragment>
  );
}
