import React, { Fragment, useState, useContext } from "react";
import { DownloadProps } from "../common/Downloads";
import DefaultHeadline from "../common/Headline";
import styled from "styled-components";
import { SecondLevelAccordion } from "../common/Accordion";
import { DownloadPagination } from "./Pagination";
import { PAGE_SIZE } from "./GuidelineTab";
import GlobalContext from "../context/GlobalContext";
import { MOBILE_HEIGHT, HEIGHT } from "../Header/constants";
import { Entry } from "../bookmarks/BookmarkPage";

const Headline = styled(DefaultHeadline)`
  margin: 0;
  flex: 1;
`;

const Header = (props: { label: string }) => {
  return (
    <Headline size={5} level={2}>
      {props.label}
    </Headline>
  );
};

interface Props {
  downloads: { type: string; downloads: DownloadProps[] }[];
  name: string;
}

export default function OtherDownloadsTab(props: Props) {
  const [pages, setPage] = useState(
    props.downloads.reduce((acc, d) => {
      acc[d.type] = 0;
      return acc;
    }, {})
  );
  const {
    theme: { isMobile }
  } = useContext(GlobalContext);
  const scrollOffset = (isMobile ? MOBILE_HEIGHT : HEIGHT) + 80;

  return (
    <Fragment>
      {props.downloads.map((d, idx) => {
        const p = pages[d.type];
        if (p === undefined) return null;

        const items = d.downloads.slice(p * PAGE_SIZE, (p + 1) * PAGE_SIZE);
        return (
          <SecondLevelAccordion
            key={d.type}
            label={<Header label={d.type} />}
            delayPanelRender={true}
            uuid={`od_${idx}`}
          >
            <DownloadPagination
              name={props.name}
              page={p}
              pageSize={PAGE_SIZE}
              total={d.downloads.length}
              items={items}
              loadItems={page => setPage({ ...pages, [d.type]: page })}
              renderItem={(d, idx) => (
                <Entry
                  {...d}
                  isDownload
                  key={d.id + idx}
                  activeText="Gemerkt"
                />
              )}
              scrollOffset={scrollOffset}
              renderEmpty={() => null}
            />
          </SecondLevelAccordion>
        );
      })}
    </Fragment>
  );
}
