import React, { useContext } from "react";
import styled from "styled-components";
import {
  colors,
  font,
  fontSizing,
  gradientBackground,
  media,
  spacing,
  THEME_SCALE
} from "../theme";
import Headline from "../common/Headline";
import Paragraph from "../common/Paragraph";
import Button from "../common/Button";
import Navigation from "./Navigation";
import { Print, Share } from "../common/icons";
import Link from "../utils/Link";
import GlobalContext from "../context/GlobalContext";
import Content, { DEFAULT_PADDING_MOBILE_ZOOM } from "../common/Content";
import { generateAutoPlacementForIE } from "../common/Grid";
import Spacer from "../common/Spacer";
import CookieBar from "../CookieBar";

const MapLink =
  "https://www.google.com/maps/dir//Hamburgische+Investitions-+und+F%C3%B6rderbank,+Besenbinderhof+31,+20097+Hamburg/@53.5483636,9.9674299,14z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47b18ee8a9ae3761:0xf8ea587ba76a45bb!2m2!1d10.0150166!2d53.5515122!3e0";

const StyledFooter = styled.footer`
  @media print {
    display: none;
  }
`;

const Contact = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: flex-start;
  padding: calc(4px * 20) calc(4px * 20) calc(4px * 20) 0;
  ${gradientBackground};
  @media screen and ${media.tablet} {
    padding: calc(4px * 20) 8vw;
  }
  @media screen and ${media.mobile} {
    padding: calc(4px * 10) 8vw;
  }

  @media screen and ${media.superTinyZoom125} {
    padding: calc(4px * 10) ${DEFAULT_PADDING_MOBILE_ZOOM};
  }
`;

const FooterHeaderContent = styled(Content)`
  display: -ms-grid;
  display: grid;
  width: 100%;
  grid-template-columns: 1.5fr 1fr;

  ${p => generateAutoPlacementForIE(p.children, "1.5fr 1fr", 0)}
  position: relative;
  @media screen and ${media.tablet} {
    grid-template-columns: 1fr;
    ${p => generateAutoPlacementForIE(p.children, "1fr", 0)}
    padding: 0;
  }
`;

const ContactLabel = styled(Headline)`
  font-weight: 400;
  color: white;
`;

const ContactHeadline = styled(Headline)`
  color: inherit;
  hyphens: none;
  display: block;
  width: 100%;
`;

const Imprint = styled("address")`
  padding: calc(4px * 20) 0 calc(4px * 20) calc(4px * 20);
  background-color: #fff;
  @media screen and ${media.tablet} {
    padding: calc(4px * 20) 8vw;
  }
  @media screen and ${media.mobile} {
    padding: calc(4px * 10) 8vw;
  }
  @media screen and ${media.superTinyZoom125} {
    padding: calc(4px * 10) ${DEFAULT_PADDING_MOBILE_ZOOM};
  }
  font-style: normal;
`;

const ImprintHeadline = styled(Headline)`
  font-weight: ${font.weightMedium};
`;

const ImprintStreet = styled(Headline)`
  white-space: pre;
  font-weight: ${font.weightLight};

  @media screen and ${media.mobile} {
    white-space: normal;
  }
`;

const ImprintParagraph = styled(Paragraph)`
  font-weight: ${font.weightRegular};
`;

const Bar = styled.div`
  position: relative;
  background: ${colors.blue};

  a,
  ul li {
    padding: calc((4px * 7) * ${THEME_SCALE}) 0;
    @media screen and ${media.mobile} {
      padding: calc(4px * 2) calc(4px * 7);
    }
  }

  a {
    color: white;
    text-decoration: none;
    ${fontSizing("footer", 16)}
  }
`;

const BarContent = styled(Content)`
  justify-content: space-between;
  align-items: flex-start;
  @media screen and ${media.mobile} {
    padding-top: calc(4px * 6);
    flex-direction: column;
  }
`;

const SubBar = styled.ul`
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  > * {
    margin-right: calc(4px * 5);
    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and ${media.mobile} {
    flex-direction: column;
  }
`;

const SubBarItem = styled.li`
  list-style: none;
  display: flex;
  a {
    padding: 0;
  }
`;

const HamburgRibbon = styled.img`
  position: absolute;
  left: 0;
  top: ${-25 * THEME_SCALE}px;
  width: ${155 * THEME_SCALE}px;
`;

const ShareBarButton = styled.div`
  white-space: nowrap;
  &:first-child {
    margin-right: calc(4px * 8);
  }

  @media screen and ${media.superTinyZoom125} {
    width: 100%;
    &:first-child {
      margin-right: 0;
    }
  }
`;

const StyledShareIcon = styled(Share)`
  color: ${colors.calypso};
  height: calc(4px * 5);
  transform: translateY(2px);
`;

const StyledPrintIcon = styled(Print)`
  color: ${colors.calypso};
  transform: translateY(2px);
  height: calc(4px * 5);
  white-space: nowrap;
`;

const ShareButton = () => {
  const onShare = () => {
    document.location.replace(
      `mailto:?subject=${document.title}&body=${window.location.href}`
    );
  };
  return (
    <ShareBarButton>
      <StyledShareIcon ariaHidden="true" />
      <Button small text onclick={() => onShare()}>
        Teilen
      </Button>
    </ShareBarButton>
  );
};

const PrintButton = () => (
  <ShareBarButton>
    <StyledPrintIcon ariaHidden="true" />
    <Button small text onclick={() => window.print()}>
      Drucken
    </Button>
  </ShareBarButton>
);

const ShareBar = styled.div`
  background-color: ${colors.lightGrey};
  padding: 1em;
`;

const ShareBarContent = styled(Content)`
  justify-content: flex-end;
  @media screen and ${media.mobile} {
    justify-content: center;
    flex-wrap: wrap;
  }
`;

const BG = styled.div`
  position: absolute;

  ${gradientBackground};
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 50%;
`;
const BGWrap = styled.div`
  position: relative;
  ${spacing("margin-bottom", 160)}
  @media screen and ${media.mobile} {
    ${spacing("margin-bottom", 80)}
  }
`;
const HideOnTinyA = styled.a`
  @media screen and ${media.tiny} {
    display: none;
  }
`;

const ContactSubheader = styled.span`
  font-size: ${font.sizeXXXL};
  font-weight: ${font.weightLight};
  display: block;
  ${spacing("margin-top", 64)};
  margin-bottom: 0.2em;

  @media screen and ${media.mobile} {
    font-size: ${font.sizeXXL};
  }
`;

const ContactCtaButton = styled(Button)`
  color: ${colors.white};
  border-color: ${colors.white};
  &:hover,
  &:active,
  &:focus {
    color: ${colors.anotherblue};
    border-color: ${colors.anotherblue};
  }
`;

const Footer = (props: { omitContacts?: boolean }) => {
  const {
    global: { footer }
  } = useContext(GlobalContext);
  return (
    <StyledFooter>
      <CookieBar />
      <ShareBar>
        <ShareBarContent padded row>
          <ShareButton />
          <PrintButton />
        </ShareBarContent>
      </ShareBar>
      <BGWrap>
        <BG />
        <FooterHeaderContent padded>
          <Contact>
            {!props.omitContacts && (
              <>
                {" "}
                <ContactLabel size={4} level={2}>
                  {footer.contactTeaser.overline}
                  <br />
                  <ContactSubheader>
                    {footer.contactTeaser.headline}
                  </ContactSubheader>
                </ContactLabel>
                <Spacer />
                <ContactCtaButton ghost inverted route="contact">
                  {footer.contactTeaser.ctaLabel}
                </ContactCtaButton>
              </>
            )}
          </Contact>
          <Imprint>
            <ImprintHeadline size={4} level={2} forwardedAs={"p"}>
              {footer.route.headline}
            </ImprintHeadline>
            <ImprintStreet size={4} level={3} forwardedAs={"p"}>
              {footer.route.address}
            </ImprintStreet>
            <ImprintParagraph small>{footer.route.text}</ImprintParagraph>
            <Spacer />
            <Button small ghost href={MapLink} prefetch={false}>
              {footer.route.ctaLabel}
            </Button>
          </Imprint>
        </FooterHeaderContent>
      </BGWrap>
      <Navigation />
      <Bar>
        <HamburgRibbon src="/static/hamburg-ribbon.svg" alt="" />
        <BarContent row padded>
          <SubBar>
            <SubBarItem>
              <Link route="impressum">
                <a>Impressum</a>
              </Link>
            </SubBarItem>
            <SubBarItem>
              <Link route="dataPrivacy">
                <a>Datenschutz</a>
              </Link>
            </SubBarItem>
            <SubBarItem>
              <Link route="accessibility">
                <a>Barrierefreiheit</a>
              </Link>
            </SubBarItem>
            <SubBarItem>
              <HideOnTinyA
                href="#"
                onClick={e => {
                  e.preventDefault();
                  window.print();
                }}
              >
                Seite drucken
              </HideOnTinyA>
            </SubBarItem>
          </SubBar>
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
            }}
          >
            Zum Seitenanfang
          </a>
        </BarContent>
      </Bar>
    </StyledFooter>
  );
};

export default Footer;
