import React, { Component } from "react";
import InitialPropsContext from "../lib/models/initialPropsContext";
import Headline from "../components/common/Headline";
import Paragraph from "../components/common/Paragraph";
import Content from "../components/common/Content";
import Section from "../components/sections/Section";
import mime from "mime";
import getBaseUrl from "../lib/getBaseUrl";
import { Api } from "../lib/CmsApi";
import Link from "../components/utils/Link";
import A from "../components/common/A";

function match(path) {
  return redirect => {
    const wildcard = /^(.+)\*$/.exec(redirect.from);
    if (wildcard) return path.startsWith(wildcard[1]);
    return path == redirect.from;
  };
}

const longestFirst = (a, b) => b.from.length - a.from.length;

interface Props {
  statusCode: number;
}

export default class Error extends Component<Props> {
  static async getInitialProps({ res, err, req }: InitialPropsContext) {
    const statusCode = res
      ? res.statusCode
      : err
      ? (err as any).statusCode
      : null;

    if (res && statusCode === 404) {
      const api = new Api({ baseUrl: `${getBaseUrl(req)}/api/cms` });
      const systemFiles = await api.listSystemFiles({
        path: { eq: req.path },
        limit: 100000
      });

      if (systemFiles && systemFiles.items[0]) {
        res.status(200);
        res.set("Content-Type", mime.getType(req.path));
        res.send(systemFiles.items[0].content);
        return {};
      }
      const redirects = (await api.listRedirects({ limit: 100000 })).items;
      const redirect = redirects.sort(longestFirst).find(match(req.path));

      if (redirect) {
        res.redirect(redirect.temporary ? 302 : 301, redirect.to._url);
        return {};
      }
    }

    return { statusCode };
  }

  render() {
    return (
      <main>
        <Section>
          <Content padded>
            <Headline size={1} level={1}>
              Das tut uns leid: Die von Ihnen gesuchte Information wurde nicht
              gefunden.
            </Headline>
            <Headline size={3} level={2}>
              Wir unterstützen Sie aber selbstverständlich trotzdem gerne bei
              Ihrem Vorhaben!
            </Headline>
            <Paragraph>
              Unser Vorschlag: Sie beginnen noch einmal auf unserer{" "}
              <Link route="index">
                <A tabIndex={0}>Startseite</A>
              </Link>
              , lassen sich bei Ihrem Anliegen direkt von unseren{" "}
              <Link route="contact">
                <A tabIndex={0}>Förder-Experten</A>
              </Link>{" "}
              helfen oder versuchen das gesuchte Dokument in unserem{" "}
              <Link route="downloads">
                <A tabIndex={0}>Download-Bereich</A>
              </Link>{" "}
              zu finden.
            </Paragraph>
          </Content>
        </Section>
      </main>
    );
  }
}
