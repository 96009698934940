import React, { useContext } from "react";
import GlobalContext from "../context/GlobalContext";
import { FlyOutOptions } from "./index";
import Link from "../utils/Link";
import getFlyOutData from "./getFlyOutData";
import { InvisibleLink, Nav, Overflow, StyledContent } from "./MobileNav";
import styled from "styled-components";
import { Desc, Name, StyledCaret } from "./FlyOut";
import { colors, font, media } from "../theme";
import {
  ArrowLeft,
  Cross,
  RoundedArrow,
  RoundedBackArrow
} from "../common/icons";
import A from "../common/A";

const Top = styled.div`
  width: 100%;
  background-color: #fff;
  flex-shrink: 0;
  color: ${colors.blue};
  font-size: calc(${font.sizeXS});
  padding: calc(4px * 4) 0;
`;
export const StyledA = styled(A)`
  display: flex;
  flex-direction: column;
`;

export const StyledArrowLeft = styled(ArrowLeft)`
  height: 1em;
  margin-right: calc(4px * 5);
`;

const ArrowWrapp = styled.div`
  display: inline-block;
  width: auto;
  margin-left: calc(4px * 2);
`;

const BackText = styled.span`
  display: inline-block;
  margin-left: 10px;
`;

const MobileFlyOut = (
  p: FlyOutOptions & { back: () => void; close: () => void }
) => {
  const global = useContext(GlobalContext);
  const data = getFlyOutData(p, global);
  return (
    <>
      <StyledContent padded>
        <Top>
          <StyledContent padded row>
            <InvisibleLink
              onClick={e => {
                e.preventDefault();
                p.back();
              }}
              aria-label="Zurück"
            >
              <RoundedBackArrow />
              <BackText>Zurück</BackText>
            </InvisibleLink>
            <InvisibleLink
              onClick={e => {
                e.preventDefault();
                p.close();
              }}
              aria-label="Menü schließen"
            >
              <Cross />
            </InvisibleLink>
          </StyledContent>
        </Top>
        <Overflow>
          <Nav>
            {data.map(({ name, description, ...linkProps }) => {
              return (
                <li key={name}>
                  <Link {...linkProps}>
                    <StyledA tabIndex={0}>
                      <Name>
                        <span className="flex-grow: 1">{name}</span>
                        <ArrowWrapp>
                          <RoundedArrow />
                        </ArrowWrapp>
                      </Name>
                      {description && <Desc>{description}</Desc>}
                    </StyledA>
                  </Link>
                </li>
              );
            })}
          </Nav>
        </Overflow>
      </StyledContent>
    </>
  );
};

export default MobileFlyOut;
