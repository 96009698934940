import React, { useContext } from "react";
import styled from "styled-components";
import { font, media, colors, fontSizing } from "./theme";
import DefaultButton from "./common/Button";
import GlobalContext from "./context/GlobalContext";
import { FirstLevelAccordion } from "./common/Accordion";
import { ContentRef } from "../lib/CmsApi";

const Container = styled.div`
  background: ${colors.focus};
  padding: 12px 24px;

  @media screen and ${media.wide} {
    padding: 16px 32px;
  }

  @media print {
    display: none;
  }
`;

const Mobile = styled.div`
  display: block;

  @media screen and ${media.wide} {
    display: none;
  }
`;

const Desktop = styled.div`
  display: none;

  @media screen and ${media.wide} {
    display: block;
  }
`;

const Accordion = styled(FirstLevelAccordion)`
  & .accordion__item > [aria-hidden="false"] {
    border-top: none;
  }

  & :last-child .accordion__item > .accordion__heading [aria-expanded="false"] {
    border-bottom: none;
  }

  & .accordion__button {
    > div:first-child {
      padding: 0;
    }
  }

  & svg {
    path:last-child {
      stroke: ${colors.white};
    }
  }
`;

const Title = styled.div`
  font-family: ${font.main};
  font-weight: ${font.weightMedium};
  font-size: 22px;
  color: ${colors.white};

  .dash {
    display: none;
  }

  .subtitle {
    display: block;
    font-size: 16px;
  }

  @media screen and ${media.wide} {
    .dash,
    .subtitle {
      display: inline;
    }
  }

  @media screen and (max-width: 294px) {
    font-size: 14px;
  }
`;

const Button = styled(DefaultButton)`
  background: ${colors.white};
  color: ${colors.blue};

  padding: 12px 24px;

  margin: 6px 12px 6px 0;
  box-shadow: 0 60px 80px -16px rgba(0, 69, 106, 0.12);
  display: inline-block;

  &:first-of-type {
    margin-top: 16px;
  }

  ${fontSizing("text", 16)};

  &:hover {
    background: ${colors.pigeonGreyLight};
  }

  @media screen and ${media.mobile} {
    width: 100%;
    max-width: 400px;
    padding: 8px 16px;
    margin-top: 4px;
    margin-bottom: 4px;
  }
`;

interface HeadlineProps {
  title?: string;
  subTitle?: string;
}

function Headline({ title, subTitle }: HeadlineProps) {
  return title ? (
    <Title>
      <b>{title}</b>
      {subTitle && (
        <>
          <span className="dash"> - </span>
          <span className="subtitle">{subTitle}</span>
        </>
      )}
    </Title>
  ) : null;
}

interface LinksProps {
  links?: {
    title?: string;
    ref?: ContentRef;
  }[];
}

function Links({ links }: LinksProps) {
  return links ? (
    <>
      {links.map(
        (link, i) =>
          link.ref && (
            <Button key={i} href={link.ref._url}>
              {link.title}
            </Button>
          )
      )}
    </>
  ) : null;
}

interface Props {
  id?: string;
}

export default function BreakingNews({ id }: Props) {
  const {
    global: { breakingNews }
  } = useContext(GlobalContext);

  if (!breakingNews || !breakingNews.show) {
    return null;
  }

  const { title, subTitle, links } = breakingNews;

  return (
    <Container id={id}>
      <Mobile>
        <Accordion
          white
          label={<Headline title={title} subTitle={subTitle} />}
          ariaLevel={1}
          uuid="breaking-news-accordion"
        >
          <Links links={links} />
        </Accordion>
      </Mobile>
      <Desktop>
        <Headline title={title} subTitle={subTitle} />
        <Links links={links} />
      </Desktop>
    </Container>
  );
}
