import styled from "styled-components";
import { media, colors, spacing } from "../../theme";
import Content from "../Content";
import DefaultLabel from "../Label";
import Numbers from "./Numbers";
import { ArrowLeft, ArrowRight } from "../icons";

const Row = styled.div`
  display: flex;
  align-items: center;
  ${spacing("margin-top", 64)}
`;

const Label = styled(DefaultLabel)`
  ${spacing("margin-left", 24)}
  ${spacing("margin-right", 24)}
  @media ${media.mobile} {
    display: none;
  }
`;
const Col = styled.div`
  flex: 1 1 50%;
`;

const DotContainer = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.grey};
  margin-bottom: 1em;
  @media ${media.desktop} {
    margin-bottom: 0;
  }
`;

const Arrow = styled.button<{ active?: boolean }>`
  display: block;
  font-size: 32px;
  color: ${({ active }) => (active ? colors.blueDark : colors.darkgrey)};
  fill: currentColor;
  cursor: ${({ active }) => (active ? "pointer" : "default")};
  background: none;
  border: none;
  padding: 0;
  &:first-child {
    ${spacing("margin-right", 64)}
  }
  &:last-child {
    ${spacing("margin-left", 64)}
  }
  @media ${media.mobile} {
    font-size: 24px;
  }
  @media ${media.tiny} {
    margin: 0 0.5em;
    font-size: 18px;
  }
  &:hover {
    opacity: 0.8;
  }
`;

interface Props {
  prev: () => void;
  next: () => void;
  goto: (i: number) => void;
  pages: number;
  index: number;
  name: string;
}

export default function Controls(props: Props) {
  const { prev, goto, next, pages, index, name } = props;

  if (pages < 2) return null;
  const shouldAllowPrev = index !== 0;
  const shouldAllowNext = index !== pages - 1;

  return (
    <Content padded>
      <Row>
        <Col>
          <DotContainer aria-label={`Navigation ${name}`}>
            <Arrow
              active={shouldAllowPrev}
              tabIndex={shouldAllowPrev ? 0 : -1}
              disabled={!shouldAllowPrev}
              onClick={shouldAllowPrev ? prev : undefined}
              aria-label="Vorherige Seite"
            >
              <ArrowLeft />
            </Arrow>
            <Label small noMargin aria-hidden="true">
              Seite
            </Label>
            <Numbers pages={pages} index={index} goto={goto} />
            <Arrow
              active={shouldAllowNext}
              tabIndex={shouldAllowNext ? 0 : -1}
              disabled={!shouldAllowNext}
              onClick={shouldAllowNext ? next : undefined}
              aria-label="Nächste Seite"
            >
              <ArrowRight />
            </Arrow>
          </DotContainer>
        </Col>
      </Row>
    </Content>
  );
}
