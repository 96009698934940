import React, { Component, Fragment } from "react";
import styled, { css } from "styled-components";
import { Router } from "../../lib/routes";

import NavBar from "./NavBar";
import InteractionMenu from "./InteractionMenu";
import Link from "../utils/Link";
import { colors, media } from "../theme";
import MobileNav from "./MobileNav";
import MobileFlyOut from "./MobileFlyOut";
import BreakingNews from "../BreakingNews";
import { GlobalContextType, withGlobalContext } from "../context/GlobalContext";
import { HEIGHT, MOBILE_HEIGHT, MOBILE_ZOOM125_HEIGHT } from "./constants";

const FixedBox = styled.div`
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  @media print {
    position: static;
  }
`;
const StyledHeader = styled.header`
  position: relative;
  height: ${HEIGHT}px;
  padding-left: calc(4px * 8);
  background: white;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.05);
  @media screen and ${media.tablet} {
    height: ${MOBILE_HEIGHT}px;
  }

  @media screen and ${media.superTinyZoom125} {
    padding: 0 10px;
    height: ${MOBILE_ZOOM125_HEIGHT}px;
  }
`;

const Spacer = styled.div<{
  breakingNewsHeight?: number;
}>`
  ${p => css`
    height: ${HEIGHT + p.breakingNewsHeight}px;
  `}

  @media screen and ${media.tablet} {
    ${p => css`
      height: ${MOBILE_HEIGHT + p.breakingNewsHeight}px;
    `}
  }

  @media screen and ${media.wide} {
    ${p => css`
      height: ${HEIGHT + p.breakingNewsHeight}px;
    `}
  }

  @media screen and ${media.superTinyZoom125} {
    ${p => css`
      height: ${MOBILE_ZOOM125_HEIGHT + p.breakingNewsHeight}px;
    `}
  }

  @media print {
    display: none;
  }
`;

const Top = styled.div`
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;

  @media screen and ${media.superTinyZoom125} {
    justify-content: initial;
    align-items: center;
    gap: 4px;
  }
`;

const StyledLogo = styled.img`
  padding-top: calc(4px * 7);
  height: 60px;
  @media screen and ${media.tablet} {
    padding-top: 10px;
    height: 50px;
  }
  @media screen and ${media.superTiny} {
    padding-top: 20px;
  }
  @media screen and ${media.superTinyZoom125} {
    display: block;
    padding: 0;
    height: 21px;
    max-height: 21px;
  }
`;
const FullBox = styled(FixedBox)<{ grey?: boolean }>`
  z-index: 100;
  bottom: 0;
  width: 100vw;
  background-color: ${p => (p.grey ? colors.paleGrey : "#fff")};
`;

const Logo = () => (
  <Link route="index">
    <a>
      <StyledLogo
        src="/static/logo.svg"
        alt="IFB Hamburg Logo - Zur Startseite"
      />
    </a>
  </Link>
);

export type FlyOutOptions =
  | { type: "segment"; id: string }
  | { type: "custom"; id: string }
  | null;

class Header extends Component<
  {
    global: GlobalContextType;
  },
  {
    flyout: FlyOutOptions;
    mobileOpen: boolean;
    scrollPosition: number;
    path: string;
    breakingNewsHeight: number;
  }
> {
  state = {
    flyout: null,
    mobileOpen: false,
    scrollPosition: 0,
    path: "",
    breakingNewsHeight: 0
  };

  get hasBreakingNews() {
    if (!this.props.global) return false;

    const {
      global: { breakingNews }
    } = this.props.global;

    return breakingNews && breakingNews.show;
  }

  onResize = (): void => {
    const bn = document.getElementById("breaking-news");
    if (!bn) return;
    this.setState({
      breakingNewsHeight: bn.getBoundingClientRect().height
    });
  };

  componentDidMount(): void {
    if (this.hasBreakingNews) {
      this.onResize();
      window.addEventListener("resize", this.onResize, false);
    }
    Router.events.on("routeChangeStart", this.closeOverlays);
  }
  componentWillUnmount(): void {
    Router.events.off("routeChangeStart", this.closeOverlays);
    window.removeEventListener("resize", this.onResize, false);
  }

  setFlyout = (s: FlyOutOptions) => {
    const { flyout } = this.state;
    if (
      flyout &&
      s.type === flyout.type &&
      s.type === flyout.type &&
      s.id === flyout.id
    ) {
      this.closeFlyout();
    } else {
      this.setState({ flyout: s });
    }
  };
  closeFlyout = () => {
    this.setState({ flyout: null });
  };
  closeOverlays = () => {
    document.body.style.overflow = "auto";
    document.body.style.height = "auto";
    document.body.style.position = "relative";
    document.getElementById("mainApp") &&
      (document.getElementById("mainApp").style.display = "block");

    setTimeout(() => {
      this.closeFlyout();
      this.setState({ mobileOpen: false });
      if (this.state.path === window.location.href) {
        window.scrollTo(0, this.state.scrollPosition);
      }
    }, 50);
  };
  toggleMobileMenu = (_e: React.MouseEvent) => {
    this.setState(
      state => ({
        mobileOpen: !state.mobileOpen,
        scrollPosition: window.pageYOffset,
        path: window.location.href
      }),
      () => {
        if (this.state.mobileOpen) {
          document.getElementById("mainApp") &&
            (document.getElementById("mainApp").style.display = "none");
          document.body.style.overflow = "hidden";
          document.body.style.height = "100vh";
          document.body.style.position = "fixed";
        }
      }
    );
  };
  render() {
    const { flyout, mobileOpen, breakingNewsHeight } = this.state;

    return (
      <Fragment>
        <Spacer breakingNewsHeight={breakingNewsHeight} />
        <FixedBox>
          {this.hasBreakingNews && <BreakingNews id="breaking-news" />}
          <StyledHeader>
            <Top>
              <Logo />
              <InteractionMenu toggleMobileMenu={this.toggleMobileMenu} />
            </Top>
            <NavBar
              mobileOpen={mobileOpen}
              currentFlyOut={flyout}
              setFlyout={this.setFlyout}
              closeFlyout={this.closeFlyout}
              openFlyoutId={flyout ? flyout.id : null}
            />
          </StyledHeader>
        </FixedBox>

        {mobileOpen && !flyout && (
          <FullBox>
            <MobileNav close={this.closeOverlays} setFlyout={this.setFlyout} />
          </FullBox>
        )}
        {mobileOpen && flyout && (
          <FullBox grey>
            <MobileFlyOut
              {...flyout}
              back={this.closeFlyout}
              close={this.closeOverlays}
            />
          </FullBox>
        )}
      </Fragment>
    );
  }
}

export default withGlobalContext(Header);
