import React from "react";

interface Props {
  errorEventId: string;
}
export default function CriticalErrorPage({ errorEventId }: Props) {
  return (
    <main>
      <h1>Es ist ein Fehler aufgetreten</h1>
      <p>
        In der Regel bedeutet das, dass sich die entsprechende Funktionalität
        zur Zeit noch in der Entwicklung befindet.
      </p>
      <p>
        Sollte dieser dennoch Fehler auf einer Seite auftreten, die bereits
        fertiggestellt wurde, können unter dem Code <b>{errorEventId}</b>
        die dazugehörigen Fehlerdetails abgerufen werden.
      </p>
    </main>
  );
}
