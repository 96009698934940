import React, { useContext } from "react";
import App, { Container, AppContext } from "next/app";
import { Api } from "../lib/CmsApi";
import { Request } from "express";
import GlobalStyle, { FOCUS_CLASS_NAME } from "../components/GlobalStyle";
import PageNotFoundError from "../lib/pageNotFoundError";
import Error from "./_error";
import { BookmarksProvider } from "../components/bookmarks/BookmarksProvider";
import Footer from "../components/Footer";
import Header from "../components/Header";
import {
  GlobalContextType,
  GlobalProvider
} from "../components/context/GlobalContext";
import TrackingContext, {
  TrackingProvider
} from "../components/context/TrackingContext";
import Credits from "../components/Credits";
import { getImageCredits } from "../lib/getImageCredits";
import sentry from "../lib/sentry";
import delayedScrollRestoration from "../lib/delayedScrollRestoration";
import CriticalErrorPage from "../components/CriticalErrorPage";
import getBaseUrl from "../lib/getBaseUrl";
import CustomPageHead from "../components/common/CustomPageHead";
import { Router } from "../lib/routes";
import CookieBar from "../components/CookieBar";

const { captureException } = sentry();

interface Props {
  global?: GlobalContextType;
  credits: string[];
  error: PageNotFoundError;
}
interface State {
  global: GlobalContextType;
  errorEventId: string | null;
  hasError: boolean;
}

class MyApp extends App<Props, State> {
  state: State = {
    global: null,
    errorEventId: null,
    hasError: false
  };
  lastRouteChangeStart: null | Date = null;

  static async getInitialProps({ Component, ctx }: AppContext) {
    let baseUrl = "";
    let globalRequest: Promise<GlobalContextType>;
    if (ctx.req) {
      baseUrl = getBaseUrl(ctx.req as Request);
    }
    const api = new Api({ baseUrl: `${baseUrl}/api/cms` }); //ctx.req as Request

    if (baseUrl !== "") {
      //Just call serverside
      globalRequest = GlobalProvider.getInitialProps(api);
    }

    try {
      const global = await globalRequest;
      const pageProps = await (Component.getInitialProps
        ? Component.getInitialProps({ ...ctx, api, globalProps: global } as any)
        : Promise.resolve({}));

      const credits = getImageCredits(pageProps);

      return { pageProps, global, credits };
    } catch (error) {
      if (error instanceof PageNotFoundError) {
        if (ctx.res) {
          ctx.res.statusCode = 404;
        }
        const [global, pageProps] = await Promise.all([
          globalRequest,
          Error.getInitialProps({ ...ctx, api } as any)
        ]);
        return {
          pageProps,
          global,
          error
        };
      }

      // Capture errors that happen during a page's getInitialProps.
      // This will work on both client and server sides.
      const errorEventId = captureException(error, ctx);
      return {
        hasError: true,
        errorEventId,
        pageProps: null
      };
    }
  }

  static getDerivedStateFromProps(props, state) {
    // If there was an error generated within getInitialProps, and we haven't
    // yet seen an error, we add it to this.state here
    return {
      hasError: props.hasError || state.hasError || false,
      errorEventId: props.errorEventId || state.errorEventId || undefined
    };
  }

  static getDerivedStateFromError() {
    // React Error Boundary here allows us to set state flagging the error (and
    // later render a fallback UI).
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === "development") throw error;

    const errorEventId = captureException(error, { errorInfo });

    // Store the event id at this point as we don't have access to it within
    // `getDerivedStateFromError`.
    this.setState({ errorEventId });
  }

  handleFocus = (e: MouseEvent | KeyboardEvent) => {
    if (e.type === "keydown" && e instanceof KeyboardEvent) {
      if (e.key === "Tab") {
        document.body.classList.add(FOCUS_CLASS_NAME);
      } else if (!e.key.startsWith("Arrow") && e.key !== "Enter") {
        document.body.classList.remove(FOCUS_CLASS_NAME);
      }
    } else {
      document.body.classList.remove(FOCUS_CLASS_NAME);
    }
  };

  onRouteChangeStart = (url: string) => {
    if (window && window._paq) {
      window._paq.push(["setCustomUrl", url]);
      window._paq.push(["setDocumentTitle", document.title]);
      this.lastRouteChangeStart = new Date();
    }
  };

  routeChangeComplete = () => {
    if (window && window._paq) {
      // remove all previously assigned custom variables, requires Matomo (formerly Piwik) 3.0.2
      window._paq.push(["deleteCustomVariables", "page"]);
      // Add time it took to fetch and render page
      window._paq.push([
        "setGenerationTimeMs",
        this.lastRouteChangeStart
          ? Date.now() - this.lastRouteChangeStart.getTime()
          : 0
      ]);

      window._paq.push(["trackPageView"]);

      // make Matomo aware of newly added content
      window._paq.push(["enableLinkTracking"]);
    }
  };

  componentDidMount(): void {
    delayedScrollRestoration();
    this.setState({ global: this.props.global });
    window.addEventListener("keydown", this.handleFocus);
    window.addEventListener("mousedown", this.handleFocus);

    // track route change
    Router.events.on("routeChangeStart", this.onRouteChangeStart);
    Router.events.on("routeChangeComplete", this.routeChangeComplete);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleFocus);
    window.removeEventListener("mousedown", this.handleFocus);
    Router.events.off("routeChangeStart", this.onRouteChangeStart);
    Router.events.off("routeChangeComplete", this.routeChangeComplete);
  }

  renderComponent() {
    const { Component, pageProps, error } = this.props;
    if (error) {
      console.log(error.message);
      console.log(error.stack);
      return <Error statusCode={404} />;
    }

    return <Component {...pageProps} />;
  }

  render() {
    const {
      global = this.state.global,
      credits,
      router,
      pageProps = {}
    } = this.props;
    return this.state.hasError ? (
      <CriticalErrorPage errorEventId={this.state.errorEventId} />
    ) : (
      <GlobalProvider global={global}>
        <TrackingProvider>
          <BookmarksProvider>
            <Container>
              <GlobalStyle />
              {/* <CookieBar /> */}
              <Header />
              <CustomPageHead
                title={pageProps.name || pageProps.title}
                desc={pageProps.description || pageProps.intro}
                seo={pageProps.seo}
              />
              <div id="mainApp">
                {this.renderComponent()}
                <Credits credits={credits} />
              </div>
              <Footer omitContacts={router.route === "/contact"} />
            </Container>
          </BookmarksProvider>
        </TrackingProvider>
      </GlobalProvider>
    );
  }
}

export default MyApp;
