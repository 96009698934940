import React from "react";
import { TabList as DefaultTabList, Tab, TAB_HEIGHT } from "../common/tabs";
import { StyledBookmarkCounter } from "../Header/InteractionMenu";
import styled from "styled-components";
import { media } from "../theme";

const TabWrapper = styled.span`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: inline-block;
  @media ${media.mobile} {
    justify-content: flex-start;
  }
`;

export const Container = styled(DefaultTabList)`
  margin-top: ${-TAB_HEIGHT}px;
  @media ${media.mobile} {
    margin-top: 0;
    text-align: left;
    white-space: initial;
    word-break: break-word;
  }
`;

interface Props {
  items: { label: string; count: number }[];
}
export default function TabList({ items, ...props }: Props) {
  const setSize = items.length;
  return (
    <Container {...props} role="tablist">
      {items.map((i, idx) => (
        <Tab key={idx} aria-setsize={setSize} aria-posinset={idx + 1}>
          <TabWrapper>
            <StyledBookmarkCounter>{i.count}</StyledBookmarkCounter>
            {i.label}
          </TabWrapper>
        </Tab>
      ))}
    </Container>
  );
}
