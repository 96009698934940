import React from "react";
import styled from "styled-components";
import DefaultLabel from "../common/Label";
import { colors, media } from "../theme";
import { Checkbox, CheckboxLabel } from "./Checkbox";

const FilterLabel = styled(DefaultLabel)<{ light?: boolean }>`
  color: ${p => (p.light ? colors.bluegrey : colors.darkgrey)};
  float: left;
  font-weight: 400;
  @media ${media.mobile} {
    margin-bottom: calc(4px * 6);
  }
`;
const Container = styled("fieldset")`
  display: flex;
  width: 100%;
  border: none;
  padding: 0;
  @media ${media.mobile} {
    display: block;
  }
`;
const Options = styled("div")`
  flex: 1 1 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @media ${media.mobile} {
    flex-direction: column;
  }
`;
const Option = styled("div")`
  margin: 4px 0;
  margin-left: calc(4px * 10);
  @media ${media.mobile} {
    margin: 0;
    flex-direction: column;
    margin-bottom: calc(4px * 6);
  }
`;

interface Props {
  label: string;
  values: { name: string; checked: boolean }[];
  onChange: ({ name: string, checked: boolean }) => void;
  lightTheme?: boolean;
}
export default function CheckboxFilter(props: Props) {
  return (
    <Container>
      <FilterLabel noMargin small light={props.lightTheme} as={"legend"}>
        {props.label}
      </FilterLabel>
      <Options>
        {props.values.map((v, idx) => (
          <Option key={v.name + idx}>
            <Checkbox
              lightTheme={props.lightTheme}
              type="checkbox"
              name={`${props.label} ${idx}`}
              value={v.name.replace(/ /g, "_")}
              id={v.name.replace(/ /g, "_") + idx}
              checked={v.checked}
              aria-labelledby={`bereich${idx}-label`}
              onChange={e =>
                props.onChange({
                  name: v.name,
                  checked: e.target.checked
                })
              }
            />
            <CheckboxLabel
              id={`bereich${idx}-label`}
              htmlFor={v.name.replace(/ /g, "_") + idx}
              aria-hidden={true}
              lightTheme={props.lightTheme}
            >
              {v.name}
            </CheckboxLabel>
          </Option>
        ))}
      </Options>
    </Container>
  );
}
