import React, { Fragment, useState } from "react";
import OtherDownloadsTab from "./OtherDownloadsTab";
import GuidelineTab from "./GuidelineTab";
import ProgramTab, { Props as ProgramTabProps } from "./ProgramTab";
import Header from "./Header";
import { DownloadProps } from "../common/Downloads";
import { Tabs, TabPanels, TabPanel as DefaultTabPanel } from "@reach/tabs";
import { Tab } from "../common/tabs";
import { Container as TabList } from "../search/Tabs";
import { Router } from "../../lib/routes";
import useDidUpdateEffect from "../../lib/useDidUpdateEffect";
import styled from "styled-components";
import CustomPageHead from "../common/CustomPageHead";
import Seo from "../../lib/models/seo";

const TabPanel = styled(DefaultTabPanel)`
  :focus {
    outline: none;
  }
`;
export const DOWNLOAD_TYPES = [
  { label: "Programminformationen", id: "programminformationen" },
  { label: "Richtlinien A-Z", id: "richtlinien" },
  { label: "Allgemeine Informationen", id: "allgemeine-informationen" }
];

export interface Props {
  programDocs: ProgramTabProps["downloads"];
  guidelineDocs: DownloadProps[];
  otherDocs: { [type: string]: DownloadProps[] };
  activeTab: string;
  seo?: Seo;
}

export default function DownloadCenterPage(props: Props) {
  const {
    programDocs,
    guidelineDocs,
    otherDocs,
    activeTab: initialTab,
    seo
  } = props;
  const [activeTab, setActiveTab] = useState(initialTab);

  useDidUpdateEffect(() => {
    if (initialTab !== activeTab) setActiveTab(initialTab);
  }, [initialTab]);

  const handleTabChange = index => {
    const type = DOWNLOAD_TYPES[index];
    if (!type) return;

    setActiveTab(type.id);
    const { tab, ...query } = Router.query;
    if (tab === DOWNLOAD_TYPES[index].id) return;

    Router.replaceRoute(
      Router.route.replace("/", ""),
      {
        ...query,
        tab: type.id
      },
      { shallow: true }
    );
  };

  return (
    <Fragment>
      <CustomPageHead title="Downloads" seo={seo} />
      <Header />
      <main>
        <Tabs
          onChange={handleTabChange}
          index={DOWNLOAD_TYPES.findIndex(t => t.id === activeTab)}
        >
          <TabList>
            {DOWNLOAD_TYPES.map((t, idx) => (
              <Tab key={idx}>{t.label}</Tab>
            ))}
          </TabList>
          <TabPanels>
            <TabPanel>
              <ProgramTab downloads={programDocs} />
            </TabPanel>
            <TabPanel>
              <GuidelineTab
                downloads={guidelineDocs}
                name={DOWNLOAD_TYPES[1].label}
              />
            </TabPanel>
            <TabPanel>
              <OtherDownloadsTab
                name={DOWNLOAD_TYPES[2].label}
                downloads={Object.entries(otherDocs).map(
                  ([type, downloads]) => ({
                    type,
                    downloads
                  })
                )}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </main>
    </Fragment>
  );
}
