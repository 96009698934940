import React, { Component } from "react";
import hoistNonReactStatic from "hoist-non-react-statics";
import * as Cookies from "es-cookie";

declare global {
  interface Window {
    _paq: (string | number)[][];
  }
}

export interface TrackingContextType {
  init: () => void;
  trackEvent: () => void;
  allowTracking: () => void;
  disallowTracking: () => void;
  dismissConsent: () => void;
  employConsent: () => void;
  hasConsentCookie: boolean;
  isEnabled: boolean;
  showCookieBar: boolean;
  isDoNotTrack: boolean;
  showModal: boolean;
  openModal: () => void;
  closeModal: () => void;
  isAllowed: () => boolean;
}

const trackingCookie = "track";
const consentCookie = "cookieconsent_dismissed";
const DaysToLive = 365;

export class TrackingProvider extends Component<
  {},
  {
    enabled: boolean;
    showCookieBar: boolean;
    hasConsentCookie: boolean;
    showModal: boolean;
  }
> {
  state = {
    enabled: false,
    showCookieBar: false,
    hasConsentCookie: false,
    showModal: false
  };
  componentDidMount(): void {
    this.init();
  }

  enableTracking = () => {
    //INIT TrackingProvider
    if (window && window._paq) window._paq.push(["rememberConsentGiven"]);
    this.setState({
      enabled: true,
      hasConsentCookie: true,
      showCookieBar: false
    });
  };

  disableTracking = () => {
    //DISABLE TrackingProvider
    if (window && window._paq) window._paq.push(["forgetConsentGiven"]);
    this.setState({
      enabled: false,
      hasConsentCookie: false,
      showCookieBar: false
    });
  };

  employConsent = () => {
    this.setState({ showCookieBar: true });
  };

  dismissConsent = () => {
    Cookies.set(consentCookie, "true", { expires: DaysToLive });
    this.setState({ showCookieBar: false, hasConsentCookie: true });
  };

  hasConsentCookie = () => {
    this.setState({
      hasConsentCookie: Cookies.get(consentCookie) !== undefined
    });

    return Cookies.get(consentCookie) !== undefined;
  };

  isAllowed = (): boolean => {
    const cookieAllowed =
      Cookies.get(trackingCookie) !== "false" &&
      Cookies.get(trackingCookie) !== undefined;
    return (
      "browser" in process && cookieAllowed && navigator.doNotTrack !== "1"
    );
  };
  isBannerClosed = (): boolean => {
    const cookiePresent = Cookies.get(trackingCookie) !== undefined;
    return "browser" in process && cookiePresent;
  };

  init = () => {
    if (this.isAllowed()) {
      this.allowTracking();
    }

    if (this.isBannerClosed()) {
      this.setState({ enabled: true, showCookieBar: false });
    } else {
      this.setState({ showCookieBar: true });
    }
  };

  trackEvent = () => {};

  allowTracking = () => {
    Cookies.set(trackingCookie, "true", { expires: DaysToLive });
    this.enableTracking();
  };

  disallowTracking = () => {
    Cookies.set(trackingCookie, "false", { expires: DaysToLive });
    this.disableTracking();
  };

  openModal = () => {
    this.setState({
      showModal: true
    });
  };
  closeModal = () => {
    this.setState({
      showModal: false
    });
  };

  render() {
    const { children } = this.props;

    return (
      <TrackingContext.Provider
        value={{
          init: this.init,
          trackEvent: this.trackEvent,
          allowTracking: this.allowTracking,
          dismissConsent: this.dismissConsent,
          employConsent: this.employConsent,
          disallowTracking: this.disallowTracking,
          hasConsentCookie: this.state.hasConsentCookie,
          isEnabled: this.state.enabled,
          showCookieBar: this.state.showCookieBar,
          isDoNotTrack: "browser" in process && navigator.doNotTrack === "1",
          showModal: this.state.showModal,
          openModal: this.openModal,
          closeModal: this.closeModal,
          isAllowed: this.isAllowed
        }}
      >
        {children}
      </TrackingContext.Provider>
    );
  }
}

const TrackingContext = React.createContext<TrackingContextType | null>(null);
export default TrackingContext;

export interface WithTracking {
  tracking: TrackingContextType | null;
}

export function withTrackingContext<P extends WithTracking>(
  Component: React.ComponentType<P>
) {
  function ComponentWithTracking(
    props: Pick<P, Exclude<keyof P, keyof WithTracking>>
  ) {
    return (
      <TrackingContext.Consumer>
        {b => <Component {...(props as any)} bookmarks={b} />}
      </TrackingContext.Consumer>
    );
  }
  return hoistNonReactStatic(ComponentWithTracking, Component);
}
