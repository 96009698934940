import React, { useContext, useEffect, useRef } from "react";
import GlobalContext, { GlobalContextType } from "../context/GlobalContext";
import { FlyOutOptions } from "./index";
import { HEIGHT } from "./constants";
import { colors, font, fontSizing } from "../theme";
import Content from "../common/Content";
import styled from "styled-components";
import { ArrowRightSmall, Cross, RoundedArrow } from "../common/icons";
import Link from "../utils/Link";
import Grid from "../common/Grid";
import getFlyOutData from "./getFlyOutData";
import AlignRight from "../common/AlignRight";
import A from "../common/A";
import FocusTrap from "focus-trap-react";

export const FlyOutBox = styled.div`
  width: 100%;
  padding: calc(4px * 8) 0;
  background-color: ${colors.paleGrey};
  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.08);
`;
export const StyledCaret = styled(ArrowRightSmall)`
  margin-left: calc(4px * 2);
  height: 0.6em;
  color: ${colors.pigeonGrey};
`;
export const StyledA = styled(A)`
  display: flex;
  flex-direction: column;
  padding: calc(4px * 6) 0;
  border-bottom: 1px solid ${colors.pigeonSuperLight};
`;
export const Name = styled.span`
  ${fontSizing("header", 22)};
  display: flex;
`;
export const Desc = styled.span`
  ${fontSizing("header", 18)};
  font-weight: normal;
`;
const Right = styled(AlignRight)`
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
`;
const CloseButton = styled.button`
  background-color: ${colors.blueDark};
  color: ${colors.white};
  font-size: ${font.sizeXS};
  padding: calc(4px * 5) calc(4px * 7);
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const StyledCross = styled(Cross)`
  margin-right: 1ch;
  width: 1em;
  height: 1em;
`;

const ArrowWrapp = styled.div`
  display: inline-block;
  margin-left: calc(4px * 2);
`;

const FlyoutWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  width: 100%;
  white-space: break-spaces;
  left: 0;
  right: 0;
  top: ${HEIGHT}px;
`;

const FlyOut = (p: FlyOutOptions & { close: () => void }) => {
  const global = useContext(GlobalContext);
  const data = getFlyOutData(p, global);
  const firstLinkRef = useRef(null);
  const segment = global.segments.items.find(item => item._id === p.id);
  const ariaLabel = segment ? `Subnavigation zu ${segment.name}` : undefined;

  useEffect(() => {
    firstLinkRef.current.children[0].focus();
  }, [firstLinkRef]);

  return (
    <FocusTrap
      focusTrapOptions={{
        allowOutsideClick: true,
        clickOutsideDeactivates: true
      }}
    >
      <FlyoutWrapper>
        <FlyOutBox aria-label={ariaLabel}>
          <Content padded>
            <Grid rowGap={0}>
              {data.map(({ name, description, ...linkProps }, idx) => {
                return (
                  <div ref={idx === 0 ? firstLinkRef : null} key={name}>
                    <Link {...linkProps}>
                      <StyledA tabIndex={0}>
                        <Name>
                          {name}
                          <ArrowWrapp>
                            <RoundedArrow />
                          </ArrowWrapp>
                        </Name>
                        {description && <Desc>{description}</Desc>}
                      </StyledA>
                    </Link>
                  </div>
                );
              })}
            </Grid>
          </Content>
        </FlyOutBox>
        <Right onClick={p.close}>
          <CloseButton onClick={p.close}>
            <StyledCross aria-hidden={"true"} />
            Menü schließen
          </CloseButton>
        </Right>
      </FlyoutWrapper>
    </FocusTrap>
  );
};

export default FlyOut;
