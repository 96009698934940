import styled from "styled-components";
import DefaultCollapsible from "./Collapsible";
import { colors, media } from "../theme";
import { defaultMaxWithStyle } from "./Content";

export const FirstLevelAccordion = styled(DefaultCollapsible)<{
  white?: boolean;
  padded?: boolean;
}>`
  & > .accordion__item > [aria-hidden="false"] {
    border-top: 2px solid
      ${p => (p.white ? colors.anotherblue : colors.bluegrey)};
  }
  .accordion__heading {
    ${p => p.white && `background: ${colors.white}`};
    border-top: 2px solid
      ${p => (p.white ? colors.anotherblue : colors.bluegrey)};
  }
  &:first-child {
    & > .accordion__item > .accordion__heading {
      border-top: none;
    }
  }
  &:last-child {
    & > .accordion__item > .accordion__heading [aria-expanded="false"] {
      border-bottom: 2px solid
        ${p => (p.white ? colors.anotherblue : colors.bluegrey)};
    }
    & > .accordion__item > [aria-hidden="false"] {
      border-top: none;
    }
  }
  &:not(:last-child) {
    & > .accordion__item > .accordion__panel > div > div {
      border-bottom: none;
    }
  }
  .accordion__button {
    ${p => (p.padded ? defaultMaxWithStyle : null)}
  }

  @media screen and ${media.mobile} {
    white-space: initial;
    word-break: break-word;
  }
`;

export const SecondLevelAccordion = styled(DefaultCollapsible)`
  &:first-child {
    .accordion__heading {
      border-top: none;
    }
  }

  &:last-child {
    border-bottom: 2px solid ${colors.anotherblue};
  }
  & > .accordion__item > div[aria-hidden="false"] {
    border-top: 2px solid ${colors.anotherblue};
  }

  .accordion__heading {
    background: ${colors.pigeonSuperLight};
    border-top: 2px solid ${colors.anotherblue};
  }
  .accordion__button {
    ${defaultMaxWithStyle}
  }
`;
