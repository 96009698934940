import React, { useState } from "react";
import styled from "styled-components";

interface Props {
  header: React.ReactNode;
  children: React.ReactNode;
}

const InvisibleButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  font-family: inherit;
  width: 100%;
`;

export const CollapsibleContainer = ({ header, children }: Props) => {
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <InvisibleButton aria-expanded={open} onClick={toggle}>
        {header}
      </InvisibleButton>
      {open && children}
    </>
  );
};
