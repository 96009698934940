import React, { ChangeEvent, Component, FormEvent, useEffect } from "react";
import styled, { css } from "styled-components";
import { MagnifingGlass } from "../common/icons";
import { colors, font, media, THEME_SCALE } from "../theme";
import { pushRouteWithQuery } from "../../lib/routes";

const SearchButton = styled.button<{ mobile?: boolean }>`
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  text-decoration: none;
  background: none;
  color: inherit;
  font: inherit;
  border: none;
  outline: none;
  margin-left: calc(4px * 5);
  padding: 0;
  ${p =>
    p.mobile &&
    css`
      margin-left: 0;
      margin-right: calc(4px * 5);
    `}

  @media screen and ${media.superTinyZoom125}{
    margin-left: 0;
    margin-right: 0;
  }
`;

const InvisibleInput = styled.input<{ mobile: boolean }>`
  width: 100%;
  background: none;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  text-align: right;
  font-weight: ${font.weightMedium};
  ::-webkit-input-placeholder {
    color: ${colors.blueDark};
    opacity: 1;
  }
  ::-moz-placeholder {
    color: ${colors.blueDark};
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: ${colors.blueDark};
    opacity: 1;
  }
  :-moz-placeholder {
    color: ${colors.blueDark};
    opacity: 1;
  }
  :placeholder-shown {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    hyphens: initial;
  }
  ${p =>
    !p.mobile
      ? css`
          @media screen and ${media.mobile} {
            display: none;
          }
        `
      : css`
          text-align: left;
        `}

  @media screen and ${media.superTinyZoom125}{
    ::placeholder {

    }
  }
`;
const CenterBox = styled.form<{ mobile?: boolean }>`
  display: flex;
  align-items: center;
  ${p => p.mobile && "flex-direction:row-reverse; width: 100%;"}

  @media screen and ${media.superTinyZoom125} {
    flex: 1;
    justify-content: center;
  }
`;

const SearchBar = class SearchBar extends Component<
  { mobile?: boolean },
  { search: string }
> {
  state = {
    search: ""
  };
  input = React.createRef<HTMLInputElement>();
  onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (
      !this.state.search &&
      !(window.matchMedia(media.mobile).matches || !this.props.mobile)
    ) {
      this.input.current.focus();
      return;
    }
    await pushRouteWithQuery("search", {
      q: this.state.search || ""
    });
    this.setState({
      search: ""
    });
    this.input.current && this.input.current.blur();
  };
  onChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ search: e.target.value });
  };

  render() {
    const { mobile } = this.props;

    return (
      <CenterBox onSubmit={this.onSubmit} mobile={mobile} role="search">
        <InvisibleInput
          onChange={this.onChange}
          placeholder="Suchbegriff eingeben"
          ref={this.input}
          value={this.state.search}
          mobile={mobile}
          name="search"
        />
        <SearchButton type="submit" mobile={mobile} aria-label="Suchen">
          <MagnifingGlass
            height={22 * THEME_SCALE}
            width={22 * THEME_SCALE}
            aria-hidden={"true"}
          />
        </SearchButton>
      </CenterBox>
    );
  }
};

export default SearchBar;
