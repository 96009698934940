import React, { useContext, useEffect, useState } from "react";
import TrackingContext from "./context/TrackingContext";
import styled from "styled-components";
import { fontSizing, media, colors } from "./theme";
import DefaultButton from "./common/Button";
import { Modal } from "react-responsive-modal";
import { Checkbox, CheckboxLabel } from "./common/Checkbox";

import { Caret } from "./common/icons";
import Collapse from "@kunukn/react-collapse";
import Link from "./utils/Link";
import A from "./common/A";

const cookiesList = {
  necessary: {
    ifb: {
      ownerName: "ifb",
      ariaLabel: "notwendig",
      cookiesList: [
        {
          cookieName: "track",
          type: "Persistent – läuft nach 1 Monat ab",
          description:
            "Dieses Cookie enthält die Information, ob der Nutzer dem Tracking zugestimmt hat oder nicht."
        },
        {
          cookieName: "cookieconsent_dismissed",
          type: "Persistent – läuft nach 1 Monat ab",
          description:
            "Dieses Cookie enthält die Information, ob dem Cookie Consent zugestimmt wurde oder nicht."
        }
      ]
    }
  },
  tracking: {
    matomo: {
      ownerName: "Matomo",
      ariaLabel: "tracking",
      cookiesList: [
        {
          cookieName: "mtm_consent",
          type: "Persistent – läuft nach 1 Monat ab",
          description:
            'Dieses Cookie enthält die Information, dass der Nutzer dem Tracking zugestimmt hat. Sobald der Nutzer das Cookie abwählt, wird das Cookie "mtm_consent" wieder durch "mtm_consent_removed" ersetzt.'
        },
        {
          cookieName: "_pk_ses.*",
          type: "Persistent – läuft nach 30 Minuten ab",
          description:
            "Dieses Cookie wird genutzt, um das Verhalten der Besucher auf der Website festzuhalten. Es wird genutzt, um Statistiken über die Websitenutzung zu sammeln wie zum Beispiel den Zeitpunkt des letzten Besuches auf der Website."
        },
        {
          cookieName: "_pk_id.*",
          type: "Persistent – läuft nach 1 Monat ab",
          description:
            "Dieses Cookie wird genutzt, um das Verhalten der Besucher auf der Website festzuhalten. Es wird genutzt, um Statistiken über die Websitenutzung zu sammeln wie zum Beispiel den Zeitpunkt des letzten Besuches auf der Website. Das Cookie enthält keine personenbezogenen Daten und wird einzig für die Websiteanalyse eingesetzt."
        }
      ]
    }
  }
};

const Container = styled.span`
  background: ${colors.white};
`;

const Button = styled(DefaultButton)`
margin-left: 50px;
${fontSizing("text", 24)}
@media screen and ${media.mobile} {
margin-left: auto;
margin-right: 0px;
margin-top: 20px;
}
`;

const BlockButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
`;
const BlockExpandCollapse = styled.button`
  display: flex;
  flex: 1;
  min-width: 6em;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  background-color: rgb(240, 248, 255);
  text-align: left;
  color: rgb(76, 76, 76);
  margin: 0px;
  padding: 0px;
  font-size: 18px;
  font-family: "Brandon Text", sans-serif;
  outline: none;
  border: rgb(240, 248, 255);
`;
const BlockContent = styled.div`
  padding-top: 8px;
`;
const BlockContainer = styled.div`
  margin-top: 8px;
  // padding: 1.125em 16px 1.5em;
  background-color: #f0f8ff;
  border-radius: 8px;
  & > span: {
    float: right;
  }
  padding-left: 16px;
`;

const CookieOwnerContainer = styled.div`
  margin-top: 8px;
  padding: 1.125em 16px 1.5em;
  border-radius: 8px;
  border-width: 1px;
  background-color: white;
`;
const CookieContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.05);
  border: 1px solid #d6d6d6;
  border-radius: 1em;
  margin-bottom: 0.5em;
  padding: 1em;
`;
const CheckboxContainer = styled.span`
  //float: right;
`;
const BottomButtonsContainer = styled.div`
  align-items: stretch;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  & > *:not(:last-child) {
    margin-right: 2%;
  }
  @media screen and ${media.mobile} {
    flex-flow: column;
    display: flex;
  }
`;
const BottomButton = styled(Button)`
  margin-bottom: 0;
  width: 32%;
  margin-left: 0;
  @media screen and ${media.mobile} {
    min-width: 100%;
  }
`;
const BottomButtonText = styled.span`
  margin-left: 8px;
`;

const BottomButtonTitle = styled.h3`
  margin-left: 8px;
`;

const CheckboxLabelDisabled = styled(CheckboxLabel)`
  display: initial;
  //position: inherit;
  border-color: #d6dbe0;
  cursor: not-allowed;
`;

const ModalContentWrapper = styled.div`
  word-break: break-word;
  hyphens: auto;
`;

const ModalCheckboxLabel = styled(CheckboxLabel)`
  display: initial;
`;

function Block({
  title,
  ariaLabel,
  children,
  type,
  checkedByDefault,
  checkboxEnabled
}) {
  const tracking = useContext(TrackingContext);
  const [blockCollapse, setBlockCollapse] = useState<boolean>(false);

  useEffect(() => {}, [tracking.isEnabled]);

  return (
    <BlockContainer>
      <BlockButton>
        <BlockExpandCollapse
          tabIndex={0}
          onClick={() => setBlockCollapse(!blockCollapse)}
          aria-expanded={blockCollapse}
        >
          <Caret isRotate={blockCollapse} />
          <BottomButtonTitle id={`checkbox_${ariaLabel}_title`}>
            {title}
          </BottomButtonTitle>
        </BlockExpandCollapse>
        {checkboxEnabled ? (
          <CheckboxContainer id={`checkbox_${ariaLabel}_container`}>
            <span>
              <Checkbox
                type="checkbox"
                id={`checkbox_${ariaLabel}`}
                checked={tracking.isAllowed()}
                aria-labelledby={`checkbox_${ariaLabel}_title`}
                onClick={e => {
                  if (tracking.isEnabled) {
                    tracking.disallowTracking();
                  } else {
                    tracking.allowTracking();
                  }
                }}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    if (tracking.isEnabled) {
                      tracking.disallowTracking();
                    } else {
                      tracking.allowTracking();
                    }
                  }
                }}
              />
              <ModalCheckboxLabel htmlFor={`checkbox_${ariaLabel}`} />
            </span>
          </CheckboxContainer>
        ) : (
          <CheckboxContainer id={`checkbox_${ariaLabel}_container`}>
            <Checkbox
              disabled
              checked={checkedByDefault}
              type="checkbox"
              id={`checkbox_${ariaLabel}`}
              aria-labelledby={`checkbox_${ariaLabel}_title`}
            />
            <CheckboxLabelDisabled htmlFor={`checkbox_${ariaLabel}`} />
          </CheckboxContainer>
        )}
      </BlockButton>
      <Collapse
        style={{ display: !blockCollapse ? "none" : "" }}
        isOpen={blockCollapse}
      >
        {children}
      </Collapse>
    </BlockContainer>
  );
}

export default function CookieModal() {
  const { dismissConsent, showModal, closeModal } = useContext(TrackingContext);

  const tracking = useContext(TrackingContext);

  useEffect(() => {
    const addButtonAriaLabel = () => {
      const closeButton = document.querySelector(
        ".react-responsive-modal-closeButton"
      );
      if (closeButton) {
        closeButton.setAttribute("aria-label", "Cookie-Dialog schließen");
      }
    };

    setTimeout(() => addButtonAriaLabel(), 500);
  }, [showModal]);

  return (
    <Container>
      <Modal
        blockScroll={false}
        open={showModal}
        onClose={closeModal}
        center
        closeOnOverlayClick={false}
        ariaLabelledby="cookies_verwalten"
      >
        <h2 id={"cookies_verwalten"}>Cookies verwalten</h2>
        <ModalContentWrapper>
          <Block
            title="Notwendig"
            ariaLabel={cookiesList.necessary.ifb.ariaLabel}
            type={cookiesList.necessary.ifb}
            checkedByDefault={true}
            checkboxEnabled={false}
          >
            <BlockContent>
              <p>
                Notwendige Cookies helfen dabei, eine Website nutzbar zu machen,
                indem sie Grundfunktionen wie Seitennavigation und Zugriff auf
                sichere Bereiche der Website ermöglichen. Ohne diese Cookies
                kann die Funktionsfähigkeit der Website nicht gewährleistet
                werden.
              </p>
              <CookieOwnerContainer>
                <h3> {cookiesList.necessary.ifb.ownerName}</h3>
                {cookiesList.necessary.ifb.cookiesList.map(cookie => {
                  return (
                    <CookieContainer key={cookie.cookieName}>
                      <h4>{cookie.cookieName}</h4>
                      <p>{cookie.description}</p>
                      <p> Type: {cookie.type}</p>
                    </CookieContainer>
                  );
                })}
              </CookieOwnerContainer>
            </BlockContent>
          </Block>
          <Block
            title="Tracking"
            ariaLabel={cookiesList.tracking.matomo.ariaLabel}
            type={cookiesList.tracking.matomo}
            checkedByDefault={tracking.isAllowed}
            checkboxEnabled={true}
          >
            <BlockContent>
              <p>
                Tracking Cookies sammeln Informationen darüber, wie eine
                Webseite genutzt wird, um die Attraktivität, Inhalt und
                Funktionalität der Webseite zu verbessern und sicherzustellen.
              </p>
              <CookieOwnerContainer>
                <h3>{cookiesList.tracking.matomo.ownerName}</h3>
                {cookiesList.tracking.matomo.cookiesList.map(cookie => {
                  return (
                    <CookieContainer key={cookie.cookieName}>
                      <h4>{cookie.cookieName}</h4>
                      <p>{cookie.description}</p>
                      <p> Type: {cookie.type}</p>
                    </CookieContainer>
                  );
                })}
              </CookieOwnerContainer>
            </BlockContent>
          </Block>
          <p>Änderungen werden automatisch gespeichert.</p>
          <p>
            <Link route="dataPrivacy">
              <A tabIndex={0}>Datenschutzerklärung</A>
            </Link>
          </p>
          <p>
            <Link route="impressum">
              <A tabIndex={0}>Impressum</A>
            </Link>{" "}
          </p>
        </ModalContentWrapper>
        <BottomButtonsContainer>
          <BottomButton
            onClick={e => {
              tracking.allowTracking();
              dismissConsent();
              closeModal();
            }}
          >
            ALLE AKZEPTIEREN
          </BottomButton>
          <BottomButton
            onClick={e => {
              tracking.disallowTracking();
              dismissConsent();
              closeModal();
            }}
          >
            ALLE ABLEHNEN
          </BottomButton>

          <BottomButton
            onClick={e => {
              closeModal();
            }}
          >
            SCHLIEßEN
          </BottomButton>
        </BottomButtonsContainer>
      </Modal>
    </Container>
  );
}
